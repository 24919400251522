import romeo from "../../../image/originals/lockdown/02googlethis.webp";
import romeo1 from "../../../image/originals/lockdown/02googlethis_back.webp";

import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "77_base",
      name: "GOOGLE THIS, 2020",
      image: romeo,
      price: "$6000.00",
      thumbnails: [romeo, romeo1],
      description: "ACRYLIC, OIL PASTEL ON CANVAS 36 IN X 36 IN",
      projectUrl: "",
      sizes: ["36 X 47"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

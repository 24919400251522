import top from '../../image/items/selene-top.webp'
import top1 from '../../image/items/selene-top1.webp'
import top2 from '../../image/items/selene-top2.webp'
import top3 from '../../image/selene-collection/1_selene.webp'


import Items from "./Item";

const Shop = () => {
  const items = [
    {
      id: "107",
      name: "Selene Princess Reversible Swimwear and Workout Top",
      image: top,
      price: "$90.00",
      thumbnails: [top, top1, top2, top3],
      description:
        "Selene Princess Swimwear Bikini Top 82/18 poly spandex one color print- reversible made in Los Angeles",
      projectUrl: "",
      sizes: ["Small", "Medium", "Large"],
    },
  ];

  return <Items items={items} />;
};

export default Shop;
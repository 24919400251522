import basquiatPrintImage from "../../../image/originals/hollywoodicons/basquiatprint.webp";
import princessDianaImage from "../../../image/originals/hollywoodicons/PrincessDiana_590x.webp";
import brittanyMurphyImage from "../../../image/originals/hollywoodicons/brittanymurphy.webp";
import audreyHepburnPrintImage from "../../../image/originals/hollywoodicons/audreyprint.webp";
import audreyBreakfastAtTiffanysPrintImage from "../../../image/originals/hollywoodicons/audreybreakfastprint.webp";
import whitneyHoustonPrintImage from "../../../image/originals/hollywoodicons/whitney.webp";
import leftEyePrintImage from "../../../image/originals/hollywoodicons/lisalefteye.webp";
import aaliyahPrintImage from "../../../image/originals/hollywoodicons/aaliyah.webp";
import marilynMonroePrintImage from "../../../image/originals/hollywoodicons/marilyn.jpg";
import princessLeiaPrintImage from "../../../image/originals/hollywoodicons/princessleia.webp";

// Quantum
import risksPrintImage from "../../../image/originals/quantum/RISKS.webp";
import leapsPrintImage from "../../../image/originals/quantum/LEAPS.webp";
import groundedForYouPrintImage from "../../../image/originals/quantum/GROUNDED.webp";
import selenePrincessPrintImage from "../../../image/originals/quantum/Selene_Princess.webp";
import selenePrintImage from "../../../image/originals/quantum/seleneprint.webp";
import paradoxPrintImage from "../../../image/originals/quantum/paradox.webp";
import floatingInSpacePrintImage from "../../../image/originals/quantum/floating.webp";
import twinFlamePrintImage from "../../../image/originals/quantum/twinFlame.webp";

// NBA
import riseOfKobePrintImage from "../../../image/originals/hollywoodicons/kobeprint.webp";

import React, { useState, useEffect, useRef } from "react";
import ProjectSample from "../../../project_samples/ProjectSamples2";

const Paintings = () => {
  const items = [
    {
      title: "Risks Print",
      imageUrl: risksPrintImage,
      projectUrl: "/RisksPrint",
    },
    {
      title: "Leaps Print",
      imageUrl: leapsPrintImage,
      projectUrl: "/LeapsPrint",
    },
    {
      title: "Twin Flame Print",
      imageUrl: twinFlamePrintImage,
      projectUrl: "/TwinFlamePrint",
    },
    {
      title: "“The Rise of Kobe” Art Print",
      imageUrl: riseOfKobePrintImage,
      projectUrl: "/TheRiseOfKobeArtPrint",
    },
    {
      title: "Grounded For You Print",
      imageUrl: groundedForYouPrintImage,
      projectUrl: "/GroundedForYouPrint",
    },
    {
      title: "Selene Princess Print",
      imageUrl: selenePrincessPrintImage,
      projectUrl: "/SelenePrincessPrint",
    },
    {
      title: "Paradox Print",
      imageUrl: paradoxPrintImage,
      projectUrl: "/ParadoxPrint",
    },
    {
      title: "Floating in Space Print",
      imageUrl: floatingInSpacePrintImage,
      projectUrl: "/FloatingInSpacePrint",
    },
    {
      title: "Whitney Houston Print",
      imageUrl: whitneyHoustonPrintImage,
      projectUrl: "/WhitneyHoustonPrint",
    },
    {
      title: "Left Eye Print",
      imageUrl: leftEyePrintImage,
      projectUrl: "/LeftEyePrint",
    },
    {
      title: "Audrey Hepburn (Breakfast at Tiffanys) Print",
      imageUrl: audreyBreakfastAtTiffanysPrintImage,
      projectUrl: "/AudreyHepburnBreakfastPrint",
    },
    {
      title: "Aaliyah Print",
      imageUrl: aaliyahPrintImage,
      projectUrl: "/AaliyahPrint",
    },
    {
      title: "Princess Leia Print",
      imageUrl: princessLeiaPrintImage,
      projectUrl: "/PrincessLeiaPrint",
    },
    {
      title: "Selene Print",
      imageUrl: selenePrintImage,
      projectUrl: "/SelenePrint",
    },
    {
      title: "Audrey Hepburn Print",
      imageUrl: audreyHepburnPrintImage,
      projectUrl: "/AudreyHepburnPrint",
    },
    {
      title: "Marilyn Monroe Print",
      imageUrl: marilynMonroePrintImage,
      projectUrl: "/MarilynMonroePrint",
    },
    {
      title: "Brittany Murphy Print",
      imageUrl: brittanyMurphyImage,
      projectUrl: "/BrittanyMurphyPrint",
    },
    {
      title: "Princess Diana Original",
      imageUrl: princessDianaImage,
      projectUrl: "/PrincessDianaOriginal",
    },
    {
      title: "Jean-Michel Basquiat Print",
      imageUrl: basquiatPrintImage,
      projectUrl: "/BasquiatPrint",
    },
  ];


  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const intervalRef = useRef(null);
  const h2Ref = useRef(null); // Ref for h2 element

  // useEffect(() => {
  //   const handleMouseOver = (event) => {
  //     const probability = 0.27; // 27% chance

  //     if (Math.random() > probability) {
  //       // If the random number is greater than the probability, do nothing
  //       return;
  //     }
  //     let iteration = 0;

  //     // Clear any existing interval
  //     if (intervalRef.current) {
  //       clearInterval(intervalRef.current);
  //     }

  //     // Check if data-value is available
  //     const dataValue = event.target.dataset.value;
  //     if (!dataValue) {
  //       console.error("data-value is not set on the target element.");
  //       return;
  //     }

  //     // Start a new interval
  //     intervalRef.current = setInterval(() => {
  //       event.target.innerText = event.target.innerText
  //         .split("")
  //         .map((letter, index) => {
  //           if (index < iteration) {
  //             return dataValue[index];
  //           }

  //           return letters[Math.floor(Math.random() * 26)];
  //         })
  //         .join("");

  //       if (iteration >= dataValue.length) {
  //         clearInterval(intervalRef.current);
  //       }

  //       iteration += 1 / 3;
  //     }, 15);
  //   };

  //   const h2Element = h2Ref.current;
  //   if (h2Element) {
  //     h2Element.addEventListener("mouseover", handleMouseOver);
  //   } else {
  //     console.error("No h2 element found.");
  //   }

  //   // Cleanup function to remove the event listener and clear interval
  //   return () => {
  //     if (intervalRef.current) {
  //       clearInterval(intervalRef.current);
  //     }
  //     if (h2Element) {
  //       h2Element.removeEventListener("mouseover", handleMouseOver);
  //     }
  //   };
  // }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    if (!isMobile) {
      const section = document.querySelector(".section-project-description");

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            section.classList.add("fade-out");
          } else {
            section.classList.remove("fade-out");
          }
        });
      });

      observer.observe(section);

      return () => observer.disconnect();
    }
  }, [isMobile]);

  return (
    <>
      <div className="Content">
        <section className="section-project-description">
          <h2
            className="project-title2"
            ref={h2Ref}
            data-value="LIMITED EDITION PRINTS"
          >
            LIMITED EDITION PRINTS
          </h2>
        </section>
        <div className="upper-section">
          <ProjectSample items={items} />
        </div>
      </div>
    </>
  );
};

export default Paintings;

import princessdiana2 from "../../../image/originals/hollywoodicons/princessdiana2.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "66_base",
      name: "Princess Diana",
      image: princessdiana2, // Main image
      price: "empty for now",
      thumbnails: [princessdiana2], // Other images (if available)
      description:
        "36 by 36 “Acrylic and Glitter on canvas(+ back of painting photo)",
      projectUrl: "/PrincessDiana",
      sizes: ["blank"],
      available: "sold",
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

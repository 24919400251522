import currentevents from "../../../image/originals/quantum/currentevents.webp";
import currentevents_back from "../../../image/originals/quantum/currentevents_back.webp";import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "6_base",
      name: "Current Events 2021",
      image: [currentevents],
      price: "$6000.00",
      thumbnails: [currentevents, currentevents_back], // Front and Back images (if available)
      description: "Acrylic and oil on canvas depicting pivotal events in 2021.",
      projectUrl: "/CurrentEvents2021",
      sizes: ["48 X 36"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

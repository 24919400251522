import seleneprint from "../../../image/originals/hollywoodicons/seleneprint.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "87_base",
      name: "Selene Print",
      image: seleneprint, // Main image without .webp
      price: "empty for now",
      thumbnails: [seleneprint], // Other images (if available)
      description: 'Roughly 20" x 20" Museum Rag Paper.',
      projectUrl: "/SelenePrint",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import spacewars from "../../../image/originals/quantum/spacewars.webp";
import spacewars_back from "../../../image/originals/quantum/spacewarsback.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "10_base",
      name: "Space Wars",
      image: [spacewars],
      price: "$10500.00",
      thumbnails: [spacewars, spacewars_back], // Front and Back images (if available)
      description: "Acrylic and oil on canvas depicting interstellar battles.",
      projectUrl: "/SpaceWars",
      sizes: ["60 X 48"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

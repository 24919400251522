import viewofla from '../../../image/originals/viewofla.webp'
import viewoflaback from '../../../image/originals/viewoflaback.webp'

import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "75_base",
      name: "VIEW OF LA (SELF-PORTRAIT), 2020",
      image: viewofla,
      price: "$6000.00",
      thumbnails: [viewofla, viewoflaback],
      description: "ACRYLIC, OIL PASTEL ON CANVAS 36 IN X 47 IN",
      projectUrl: "",
      sizes: ["36 X 47"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;
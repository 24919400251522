import michael from "../../../image/originals/nba/michael.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "48_base",
      name: "Michael Jordan",
      image: michael, // Main image without .webp
      price: "$9000.00",
      thumbnails: [michael], // Other images (if available)
      description: "48” X 36” MIXED MEDIA, GLITTER & ACRYLIC ON CANVAS",
      projectUrl: "/MichaelJordan",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

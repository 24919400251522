import floating from "../../../image/originals/quantum/floating.webp";import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "13_base",
      name: "Floating in Space",
      image: [floating],
      price: "$14000.00",
      thumbnails: [floating], // Front and Back images (if available)
      description: "Acrylic and oil on canvas capturing the feeling of floating in space.",
      projectUrl: "/Floating",
      sizes: ["60 X 48"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import princessleia from "../../../image/originals/hollywoodicons/princessleia.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "91_base",
      name: "Princess Leia Print",
      image: princessleia, // Main image without .webp
      price: "empty for now",
      thumbnails: [princessleia], // Other images (if available)
      description: '"Roughly 20" x 20" Museum Rag Paper.',
      projectUrl: "/PrincessLeiaPrint",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

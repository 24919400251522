import image015 from "../../../image/originals/gold/015.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "27_base",
      name: "Mother Cradling Child",
      image: image015,
      price: "$9250.00",
      thumbnails: [image015],
      description: "Acrylic and oil on canvas of a mother cradling her child.",
      projectUrl: "/MotherCradlingChild",
      sizes: ["40 X 50"],
      available: "sold",
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

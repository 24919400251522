import MoSalah from "../../../image/originals/futbol/MoSalah.webp"; import MoSalah2 from "../../../image/originals/futbol/MoSalah2.webp"; 
import ItemWide from "../ItemWide";

const Shop = () => {
  const items = [
    {
      id: "24_base",
      name: "MO SALAH",
      image: MoSalah, // Main image without .webp
      price: "$7000.00",
      thumbnails: [MoSalah, MoSalah2], // Other images (if available)
      description: "63 1/2 IN X 38 IN ACRYLIC, OIL PASTEL ON CANVAS",
      projectUrl: "/MohamedSalah",
      sizes: ["blank"],
    },
  ];

  return <ItemWide items={items} />;
};

export default Shop;

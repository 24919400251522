import audreyprint from "../../../image/originals/hollywoodicons/audreyprint.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "109_base",
      name: "Audrey Hepburn Print",
      image: audreyprint, // Main image without .webp
      price: "empty for now",
      thumbnails: [audreyprint], // Other images (if available)
      description: '14" x 14" ACRYLIC ON CANVAS.',
      projectUrl: "/AudreyHepburnPrint",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

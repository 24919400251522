import thirtyOne from "../../../image/originals/quantum/keytoyourheart.webp";
import thirtyTwo from "../../../image/originals/quantum/keytoheart_back.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "19_base",
      name: "Key to your Heart",
      image: [thirtyOne],
      price: "$11000.00",
      thumbnails: [thirtyOne, thirtyTwo], // Front and Back images (if available)
      description: "Acrylic and oil on canvas illustrating the key to the heart.",
      projectUrl: "/KeyToYourHeart",
      sizes: ["48 X 36"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

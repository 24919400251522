import thanos from "../../../image/originals/quantum/thanos.webp";
import thanos_back from "../../../image/originals/quantum/thanos_back.webp";import ItemWide from "../ItemWide";

const Shop = () => {
  const items = [
    {
      id: "14_base",
      name: "Thanos in Space",
      image: [thanos],
      price: "$10000.00",
      thumbnails: [thanos, thanos_back], // Front and Back images (if available)
      description: "Acrylic and oil on canvas capturing the might of Thanos in space.",
      projectUrl: "/ThanosInSpace",
      sizes: ["60 X 48"],
    },
  ];

  return <ItemWide items={items} />;
};

export default Shop;

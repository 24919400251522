import GROUNDED from "../../../image/originals/quantum/GROUNDED.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "97_base",
      name: "Grounded For You Print",
      image: GROUNDED, // Main image without .webp
      price: "empty for now",
      thumbnails: [GROUNDED], // Other images (if available)
      description: "20 x 20” Museum Rag Paper.",
      projectUrl: "/GroundedForYouPrint",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

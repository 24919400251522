import eighteen from "../../../image/originals/quantum/infiniteflames.webp";
import eighteen2 from "../../../image/originals/quantum/infinite_flames_back.webp";

import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "15_base",
      name: "Infinite Flames",
      image: [eighteen],
      price: "$7000.00",
      thumbnails: [eighteen, eighteen2], // Front and Back images (if available)
      description: "Acrylic and oil on canvas representing infinite flames.",
      projectUrl: "/InfiniteFlames",
      sizes: ["48 X 36"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

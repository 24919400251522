import bottom1 from '../../image/selene-collection/bikiniBottom/bottom1.webp'
import bottom2 from '../../image/selene-collection/bikiniBottom/bottom2.webp'
import bottom3 from '../../image/selene-collection/bikiniBottom/bottom3.webp'
import bottom4 from '../../image/selene-collection/bikiniBottom/bottom4.webp'


import Items from "./Item";

const Shop = () => {
  const items = [
    {
      id: "104",
      name: "Selene Princess Swimwear High Waisted Reversible Bikini Bottom",
      image: bottom1,
      price: "$90.00",
      thumbnails: [bottom1, bottom2, bottom3, bottom4],
      description:
        "82/18 poly spandex one color print- reversible made in Los Angeles",
      projectUrl: "",
      sizes: ["Small", "Medium", "Large", "XLarge"],
    },
  ];

  return <Items items={items} />;
};

export default Shop;
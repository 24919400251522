import iam from '../../../image/stickers/iam1.webp'
import without from '../../../image/stickers/justa.webp'
import artro from "../../../image/stickers/artronaut1.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "78_base",
      name: "3 Art Stickers",
      image: [artro],
      price: "$13.50",
      thumbnails: [artro, iam, without], // Front and Back images (if available)
      description:
        "1x “ I AM ART” sticker 1x “aRTronaut” sticker 1x “Without RT” sticker.",
      projectUrl: "/StickerBundle",
      sizes: ["50 X 40"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

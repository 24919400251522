import traeyoung from "../../../image/originals/nba/traeyoung.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: 0,
      name: "Trae Young 2020",
      image: traeyoung, // Main image without .webp
      price: "empty for now",
      thumbnails: [traeyoung], // Other images (if available)
      description: "28” X 38” ACRYLIC & GLOW IN THE DARK ON CANVAS",
      projectUrl: "/TraeYoung2020",
      sizes: ["blank"],
      available: "sold",
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

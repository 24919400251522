import React, { useEffect } from "react";

const MailchimpForm = () => {
  useEffect(() => {
    // Create the script element for Mailchimp validation
    const script = document.createElement("script");
    script.src = "//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js";
    script.async = true;
    document.body.appendChild(script);

    // Once the script loads, define fnames and ftypes
    script.onload = () => {
      (function ($) {
        window.fnames = new Array();
        window.ftypes = new Array();
        window.fnames[0] = "EMAIL";
        window.ftypes[0] = "email";
        
      })(window.jQuery); // Use window.jQuery if you're using jQuery from the script
    };

    // Cleanup the script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div id="mc_embed_signup">
      <form
        action="https://reenatolentino.us3.list-manage.com/subscribe/post?u=fb538b1956c1feb7b9b4e3cf1&amp;id=49e87c0665&amp;f_id=009fc0e5f0"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        noValidate
      >
        <div id="mc_embed_signup_scroll">
          <h2>Subscribe to the RT Newsletter</h2>
          <div className="indicates-required">
            {/* <span className="asterisk">*</span> indicates required */}
          </div>
          <div className="mc-field-group">
            <label htmlFor="mce-EMAIL">
              Email Address <span className="asterisk">*</span>
            </label>
            <input
              type="email"
              name="EMAIL"
              className="required email"
              id="mce-EMAIL"
              required
            />
          </div>
          <div id="mce-responses" className="clear foot">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: "none" }}
            ></div>
            <div
              className="response"
              id="mce-success-response"
              style={{ display: "none" }}
            ></div>
          </div>
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_a6527ed862f281e435a8fe6e7_a1204496a9"
              tabIndex="-1"
              defaultValue=""
            />
          </div>
          <div className="clear foot">
            <input
              type="submit"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="button"
              value="Subscribe"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default MailchimpForm;

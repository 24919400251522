import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

import NaviBar from "./components/navbar/NaviBar";
import Projects from "./screens/Projects";

// import Resume from './screens/Resume'
import SeleneBottom from "./screens/items/SeleneBottom";
import Success from "./screens/Success";
import Merch from "./screens/collections/merch/Merch";
import LazuliCollection from "./screens/collections/LazuliCollection";
import SeleneCollection from "./screens/collections/SeleneCollection";
import StickersCollection from './screens/collections/StickersCollection'
import GoldCollection from './screens/collections/GoldCollection'
import QuantumCollection from './screens/collections/QuantumCollection'
import AbstractsCollection from "./screens/collections/AbstractsCollection";
import NBAAllStarsCollection from './screens/collections/NBACollection'
import FutbolCollection from './screens/collections/FutbolCollection'
import LockdownCollection from './screens/collections/LockdownCollection'
import RadicalCollection from './screens/collections/RadicalCollection'
import HollywoodCollection from './screens/collections/HollywoodCollection'
import Paintings from "./screens/collections/paintings/Paintings";
import Prints from "./screens/collections/prints/Prints";
// import PrincessDiana from "./screens/items/PrincessDiana";
import ViewOfLa from "./screens/items/lockdown/ViewOfLa";
import TwinFlame from './screens/items/TwinFlame'
import SeleneBikiniTop from "./screens/items/SeleneBikiniTop";
import SeleneShorts from "./screens/items/SeleneShorts";
import SeleneDress from "./screens/items/SeleneDress";
import SelenePants from "./screens/items/SelenePants";
import FlirtyLazuliTwoPiece from "./screens/items/FlirtyLazuliTwoPiece";
import FlirtyLazuliTop from "./screens/items/FlirtyLazuliTop";
import FlirtyLazuliSkirt from "./screens/items/FlirtyLazuliSkirt";
import Purses from "./screens/items/Purses";
import RTronaut from "./screens/items/RTronaut";
import Mother from "./screens/items/Mother"
import Floating from './screens/items/Floating'
// import Risk from './screens/items/Risks'
import Reyna from './screens/items/gold/Reyna'
import L from './screens/items/gold/L'
import Vibe from './screens/items/gold/Vibe'
import CradlingBaby from './screens/items/gold/CradlingBaby'
import GrowingFamily from './screens/items/gold/GrowingFamily'
import Pregnant from './screens/items/gold/Pregnant'

import Deeper from "./screens/items/gold/Deeper";
import Familyhood from "./screens/items/gold/Familyhood";
import FatherFamily from "./screens/items/gold/FatherFamily";
import FatherLounging from "./screens/items/gold/FatherLounging";
import MamaBebe from "./screens/items/gold/MamaBebe";
import MotherAndChild from "./screens/items/gold/MotherAndChild";
import MotherCradle from "./screens/items/gold/MotherCradle";
import MotherInfant from "./screens/items/gold/MotherInfant";
import MotherPlaying from "./screens/items/gold/MotherPlaying";
import Motherhood from "./screens/items/gold/Motherhood";
import QueenAndPets from "./screens/items/gold/QueenAndPets";
import QueenChild from "./screens/items/gold/QueenChild";
import QueenPet from "./screens/items/gold/QueenPet";
import QueenToddler from "./screens/items/gold/QueenToddler";
import YoungLove from "./screens/items/gold/YoungLove";


import CosmoWarrior from "./screens/items/quantum/CosmoWarrior";
import FollowYourGut from "./screens/items/quantum/FollowYourGut";
import LeapsOriginal from "./screens/items/quantum/LeapsOriginal";
import SpaceWars from "./screens/items/quantum/SpaceWars";
import CreativeCosmonaut from "./screens/items/quantum/CreativeCosmonaut";
import ForeverEntangled from "./screens/items/quantum/ForeverEntangled";
import MyopicMen from "./screens/items/quantum/MyopicMen";
import Thanos from "./screens/items/quantum/Thanos";
import CurrentEvents from "./screens/items/quantum/CurrentEvents";
import GroundedForYou from "./screens/items/quantum/GroundedForYou";
import ParadoxOriginal from "./screens/items/quantum/ParadoxOriginal";
import TwinFlameOriginal from "./screens/items/quantum/TwinFlameOriginal";
import Escaping from "./screens/items/quantum/Escaping";
import InfiniteFlames from "./screens/items/quantum/InfiniteFlames";
import RisksOriginal from "./screens/items/quantum/RisksOriginal";
import UniverseOriginal from "./screens/items/quantum/UniverseOriginal";
import FloatingInSpace from "./screens/items/quantum/FloatingInSpace";
import KeyToYourHeart from "./screens/items/quantum/KeyToYourHeart";
import SelenePrincess from "./screens/items/quantum/SelenePrincess";
import StickerBundle from "./screens/items/stickers/Bundle";
import Artronaut from "./screens/items/stickers/Artronaut";
import Without from "./screens/items/stickers/Without"
import Iam from "./screens/items/stickers/Iam"

import CancelCulture from "./screens/items/abstracts/CancelCulture";
import FoolThemNotMe from "./screens/items/abstracts/FoolThemNotMe";
import ItCameInADream from "./screens/items/abstracts/ItCameInADream";
import DivineFeminine from "./screens/items/abstracts/DivineFeminine";
import Intentions from "./screens/items/abstracts/Intentions";
import WWW from "./screens/items/abstracts/WWW";


import Frida from "./screens/items/hollywoodicons/Frida";
import BobMarleyDreads from "./screens/items/hollywoodicons/BobMarleyDreads";
import BasquiatDetails from "./screens/items/hollywoodicons/BasquiatDetails";
import BobMarleyJammin from "./screens/items/hollywoodicons/BobMarleyJammin";
import FreshPrince from "./screens/items/hollywoodicons/FreshPrince";
import PrincessLeiaOriginal from "./screens/items/hollywoodicons/PrincessLeiaOriginal";
import WhitneyHoustonOriginal from "./screens/items/hollywoodicons/WhitneyHoustonOriginal";
import DavidBowie from "./screens/items/hollywoodicons/DavidBowie";
import PrincessDianaOriginal from "./screens/items/hollywoodicons/PrincessDianaOriginal";
import ElizabethTaylor from "./screens/items/hollywoodicons/ElizabethTaylor";
import JimMorrison from "./screens/items/hollywoodicons/JimMorrison";
import BruceLee from "./screens/items/hollywoodicons/BruceLee";
import MichaelJacksonOriginal from "./screens/items/hollywoodicons/MichaelJacksonOriginal";
import BobMarley from "./screens/items/hollywoodicons/BOBMARLEY";
import JDilla from "./screens/items/hollywoodicons/JDilla";
import SophiaLoren from "./screens/items/hollywoodicons/SophiaLoren";
import AudreyHepburn from "./screens/items/hollywoodicons/AudreyHepburn";
import VirgilAbloh from "./screens/items/hollywoodicons/VirgilAbloh";
import LeftEyeOriginal from "./screens/items/hollywoodicons/LeftEyeOriginal";
import Prince from "./screens/items/hollywoodicons/Prince";
import TigerWoods from "./screens/items/hollywoodicons/TigerWoods";
import ElonMusk from "./screens/items/hollywoodicons/ElonMusk";
import ElizabethTaylor2 from "./screens/items/hollywoodicons/ElizabethTaylor2";
import PrincessDiana from "./screens/items/hollywoodicons/PrincessDiana";
import MacMiller from "./screens/items/hollywoodicons/MacMiller";
import MuhammadAli from "./screens/items/hollywoodicons/MuhammadAli";

import LebronLA from "./screens/items/nba/LeBronLA";
import StephenCurry from "./screens/items/nba/StephenCurry";
import MichaelJordan23 from "./screens/items/nba/MichaelJordan23";
import VicOladipo from "./screens/items/nba/VicOladipo";
import Shaq from "./screens/items/nba/Shaq";
import TraeYoung2020 from "./screens/items/nba/TraeYoung2020";
import MichaelJordan from "./screens/items/nba/MichaelJordan";
import RussellWestbrook from "./screens/items/nba/RussellWestbrook";
import LeBronLakers from "./screens/items/nba/LeBronLakers";
import LeBronCleveland from "./screens/items/nba/LeBronCleveland";
import KyrieIrving from "./screens/items/nba/KyrieIrving";
import KevinDurant from "./screens/items/nba/KevinDurant";
import KawhiLeonard from "./screens/items/nba/KawhiLeonard";
import JamesHarden from "./screens/items/nba/JamesHarden";
import Giannis from "./screens/items/nba/Giannis";
import AnthonyDavis from "./screens/items/nba/AnthonyDavis";

import Neymar from "./screens/items/futbol/Neymar";
import LionelMessi from "./screens/items/futbol/LionelMessi";
import HarryKane from "./screens/items/futbol/HarryKane";
import MohamedSalah from "./screens/items/futbol/MohamedSalah";
import KylianMbappe from "./screens/items/futbol/KylianMbappe";


import RomeoAndJuliet from "./screens/items/lockdown/RomeoJuliet"
import GoogleThis from "./screens/items/lockdown/Google"
import WashingHands from "./screens/items/lockdown/Washing";

import ReenaRose from "./screens/items/radical/Radical"

import HollywoodPrints from "./screens/collections/prints/HollywoodPrints"
import QuantumPrints from "./screens/collections/prints/QuantumPrints"

import AaliyahPrint from "./screens/items/prints/AaliyahPrint"


// import AaliyahPrint from "./screens/items/prints/AaliyahPrint";
import AudreyHepburnPrint from "./screens/items/prints/AudreyHepburnPrint";
import AudreyHepburnBreakfastPrint from "./screens/items/prints/AudreyHepburnBreakfastPrint";
import BasquiatPrint from "./screens/items/prints/JeanMichelBasquiatPrint";
import BrittanyMurphyPrint from "./screens/items/prints/BrittanyMurphyPrint";
import PrincessDianaPrint from "./screens/items/prints/PrincessDianaPrint";
import KobePrint from "./screens/items/prints/KobePrint";
import LeftEyePrint from "./screens/items/prints/LeftEyePrint";
import MarilynMonroePrint from "./screens/items/prints/MarilynMonroePrint";
import PrincessLeiaPrint from "./screens/items/prints/PrincessLeiaPrint";
import SelenePrint from "./screens/items/prints/SelenePrint";
import WhitneyHoustonPrint from "./screens/items/prints/WhitneyHoustonPrint";

import FloatingInSpacePrint from "./screens/items/prints/FloatinginSpacePrint";


// import FloatingInSpacePrint from "./screens/items/prints/FloatinginSpacePrint";
import ParadoxPrint from "./screens/items/prints/ParadoxPrint";
import SelenePrincessPrint from "./screens/items/prints/SelenePrincessPrint";
import GroundedForYouPrint from "./screens/items/prints/GroundedForYouPrint";
import TwinFlamePrint from "./screens/items/prints/TwinFlamePrint";
import LeapsPrint from "./screens/items/prints/LeapsPrint";
import RisksPrint from "./screens/items/prints/RisksPrint";
import TheRiseOfKobeArtPrint from "./screens/items/prints/TheRiseOfKobeArtPrint";

import { Analytics } from "@vercel/analytics/react";


import { CartProvider } from "./CartContext";

class App extends React.Component {
  render() {
    return (
      <>
        <Router>
          <link
            href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap"
            rel="stylesheet"
          ></link>
          <div className="Heading">
            <header className="App-header">
              <NaviBar />
            </header>
            <Analytics />
          </div>
          <div className="Bod">
            <CartProvider>
              <Routes>
                {/* Nav Routes */}
                <Route path="/" element={<Projects />} />
                <Route path="/Projects" element={<Projects />} />
                <Route path="/Success" element={<Success />} />
                <Route path="collections/merch" element={<Merch />} />
                <Route
                  path="collections/original-paintings-1"
                  element={<Paintings />}
                />
                <Route path="/Prints" element={<Prints />} />
                {/* Collections */}
                <Route
                  path="/LazuliCollection"
                  element={<LazuliCollection />}
                />
                <Route
                  path="/SeleneCollection"
                  element={<SeleneCollection />}
                />
                <Route
                  path="/StickersCollection"
                  element={<StickersCollection />}
                />
                <Route path="/GoldCollection" element={<GoldCollection />} />
                <Route
                  path="/AbstractsCollection"
                  element={<AbstractsCollection />}
                />
                <Route
                  path="/NBAAllStars"
                  element={<NBAAllStarsCollection />}
                />
                <Route
                  path="/FutbolCollection"
                  element={<FutbolCollection />}
                />
                <Route
                  path="/LockdownCollection"
                  element={<LockdownCollection />}
                />
                <Route
                  path="/RadicalCollection"
                  element={<RadicalCollection />}
                />
                <Route
                  path="/HollywoodCollection"
                  element={<HollywoodCollection />}
                />
                {/* Shop Items */}
                {/* Merch */}
                <Route path="/FlirtyLazuliTop" element={<FlirtyLazuliTop />} />
                <Route
                  path="/FlirtyLazuliTwoPiece"
                  element={<FlirtyLazuliTwoPiece />}
                />
                <Route
                  path="/FlirtyLazuliSkirt"
                  element={<FlirtyLazuliSkirt />}
                />
                <Route path="/Shop" element={<SeleneBottom />} />
                <Route path="/SeleneBikiniTop" element={<SeleneBikiniTop />} />
                <Route path="/SeleneShorts" element={<SeleneShorts />} />
                <Route path="/SeleneDress" element={<SeleneDress />} />
                <Route path="/SelenePants" element={<SelenePants />} />
                <Route path="/Purses" element={<Purses />} />
                <Route path="/RTronaut" element={<RTronaut />} />
                {/* Paintings */}
                <Route path="/PrincessDiana" element={<PrincessDiana />} />
                <Route path="/Mother" element={<Mother />} />
                <Route path="/PrincessDiana" element={<PrincessDiana />} />
                <Route path="/ViewOfLa" element={<ViewOfLa />} />
                <Route path="/TwinFlame" element={<TwinFlame />} />
                <Route
                  path="/QuantumCollection"
                  element={<QuantumCollection />}
                />
                <Route path="/Floating" element={<Floating />} />
                {/* <Route path="/Risk" element={<Risk />} /> */}
                <Route path="/Reyna" element={<Reyna />} />
                <Route path="/L" element={<L />} />
                <Route path="/Vibe" element={<Vibe />} />
                <Route path="/CradlingBaby" element={<CradlingBaby />} />
                <Route path="/GrowingFamily" element={<GrowingFamily />} />
                <Route path="/Pregnant" element={<Pregnant />} />
                <Route path="/Deeper" element={<Deeper />} />
                <Route path="/Familyhood" element={<Familyhood />} />
                <Route path="/FatherFamily" element={<FatherFamily />} />
                <Route path="/FatherLounging" element={<FatherLounging />} />
                <Route path="/MamaBebe" element={<MamaBebe />} />
                <Route path="/MotherAndChild" element={<MotherAndChild />} />
                <Route path="/MotherCradle" element={<MotherCradle />} />
                <Route path="/MotherInfant" element={<MotherInfant />} />
                <Route path="/MotherPlaying" element={<MotherPlaying />} />
                <Route path="/Motherhood" element={<Motherhood />} />
                <Route path="/QueenAndPets" element={<QueenAndPets />} />
                <Route path="/QueenChild" element={<QueenChild />} />
                <Route path="/QueenPet" element={<QueenPet />} />
                <Route path="/QueenToddler" element={<QueenToddler />} />
                <Route path="/YoungLove" element={<YoungLove />} />
                <Route path="/CosmoWarrior" element={<CosmoWarrior />} />
                <Route path="/FollowYourGut" element={<FollowYourGut />} />
                <Route path="/LeapsOriginal" element={<LeapsOriginal />} />
                <Route path="/SpaceWars" element={<SpaceWars />} />
                <Route
                  path="/CreativeCosmonaut"
                  element={<CreativeCosmonaut />}
                />
                <Route
                  path="/ForeverEntangled"
                  element={<ForeverEntangled />}
                />
                <Route path="/MyopicMen" element={<MyopicMen />} />
                <Route path="/Thanos" element={<Thanos />} />
                <Route path="/CurrentEvents" element={<CurrentEvents />} />
                <Route path="/GroundedForYou" element={<GroundedForYou />} />
                <Route path="/ParadoxOriginal" element={<ParadoxOriginal />} />
                <Route
                  path="/TwinFlameOriginal"
                  element={<TwinFlameOriginal />}
                />
                <Route path="/Escaping" element={<Escaping />} />
                <Route path="/InfiniteFlames" element={<InfiniteFlames />} />
                <Route path="/RisksOriginal" element={<RisksOriginal />} />
                <Route
                  path="/UniverseOriginal"
                  element={<UniverseOriginal />}
                />
                <Route path="/FloatingInSpace" element={<FloatingInSpace />} />
                <Route path="/KeyToYourHeart" element={<KeyToYourHeart />} />
                <Route path="/SelenePrincess" element={<SelenePrincess />} />
                <Route path="/StickerBundle" element={<StickerBundle />} />
                <Route path="/Artronaut" element={<Artronaut />} />
                <Route path="/Without" element={<Without />} />
                <Route path="/Iam" element={<Iam />} />
                <Route path="/CancelCulture" element={<CancelCulture />} />;
                <Route path="/FoolThemNotMe" element={<FoolThemNotMe />} />;
                <Route path="/ItCameInADream" element={<ItCameInADream />} />;
                <Route path="/DivineFeminine" element={<DivineFeminine />} />;
                <Route path="/Intentions" element={<Intentions />} />;
                <Route path="/WWW" element={<WWW />} />;
                <Route path="/Frida" element={<Frida />} />
                <Route path="/BobMarleyDreads" element={<BobMarleyDreads />} />
                <Route path="/BasquiatDetails" element={<BasquiatDetails />} />
                <Route path="/BobMarleyJammin" element={<BobMarleyJammin />} />
                <Route path="/FreshPrince" element={<FreshPrince />} />
                <Route
                  path="/PrincessLeiaOriginal"
                  element={<PrincessLeiaOriginal />}
                />
                <Route
                  path="/WhitneyHoustonOriginal"
                  element={<WhitneyHoustonOriginal />}
                />
                <Route path="/DavidBowie" element={<DavidBowie />} />
                <Route
                  path="/PrincessDianaOriginal"
                  element={<PrincessDianaOriginal />}
                />
                <Route path="/ElizabethTaylor" element={<ElizabethTaylor />} />
                <Route path="/JimMorrison" element={<JimMorrison />} />
                <Route path="/BruceLee" element={<BruceLee />} />
                <Route
                  path="/MichaelJacksonOriginal"
                  element={<MichaelJacksonOriginal />}
                />
                <Route path="/BobMarley" element={<BobMarley />} />
                <Route path="/JDilla" element={<JDilla />} />
                <Route path="/SophiaLoren" element={<SophiaLoren />} />
                <Route path="/AudreyHepburn" element={<AudreyHepburn />} />
                <Route path="/VirgilAbloh" element={<VirgilAbloh />} />
                <Route path="/LeftEyeOriginal" element={<LeftEyeOriginal />} />
                <Route path="/Prince" element={<Prince />} />
                <Route path="/TigerWoods" element={<TigerWoods />} />
                <Route path="/ElonMusk" element={<ElonMusk />} />
                <Route
                  path="/ElizabethTaylor2"
                  element={<ElizabethTaylor2 />}
                />
                <Route path="/PrincessDiana" element={<PrincessDiana />} />
                <Route path="/MacMiller" element={<MacMiller />} />
                <Route path="/MuhammadAli" element={<MuhammadAli />} />
                <Route path="/LebronLA" element={<LebronLA />} />;
                <Route path="/StephenCurry" element={<StephenCurry />} />;
                <Route path="/MichaelJordan23" element={<MichaelJordan23 />} />;
                <Route path="/VicOladipo" element={<VicOladipo />} />;
                <Route path="/Shaq" element={<Shaq />} />;
                <Route path="/TraeYoung2020" element={<TraeYoung2020 />} />;
                <Route path="/MichaelJordan" element={<MichaelJordan />} />;
                <Route
                  path="/RussellWestbrook"
                  element={<RussellWestbrook />}
                />
                ;
                <Route path="/LeBronLakers" element={<LeBronLakers />} />;
                <Route path="/LeBronCleveland" element={<LeBronCleveland />} />;
                <Route path="/KyrieIrving" element={<KyrieIrving />} />;
                <Route path="/KevinDurant" element={<KevinDurant />} />;
                <Route path="/KawhiLeonard" element={<KawhiLeonard />} />;
                <Route path="/JamesHarden" element={<JamesHarden />} />;
                <Route path="/Giannis" element={<Giannis />} />;
                <Route path="/AnthonyDavis" element={<AnthonyDavis />} />;
                <Route path="/Neymar" element={<Neymar />} />;
                <Route path="/LionelMessi" element={<LionelMessi />} />;
                <Route path="/HarryKane" element={<HarryKane />} />;
                <Route path="/MohamedSalah" element={<MohamedSalah />} />;
                <Route path="/KylianMbappe" element={<KylianMbappe />} />;
                <Route path="/RomeoAndJuliet" element={<RomeoAndJuliet />} />;
                <Route path="/GoogleThis" element={<GoogleThis />} />;
                <Route path="/WashingHands" element={<WashingHands />} />;
                <Route path="/ReenaRose" element={<ReenaRose />} />;
                <Route path="/HollywoodPrints" element={<HollywoodPrints />} />;
                <Route path="/QuantumPrints" element={<QuantumPrints />} />;
                <Route path="/AaliyahPrint" element={<AaliyahPrint />} />
                <Route
                  path="/AudreyHepburnPrint"
                  element={<AudreyHepburnPrint />}
                />
                <Route
                  path="/AudreyHepburnBreakfastPrint"
                  element={<AudreyHepburnBreakfastPrint />}
                />
                <Route path="/BasquiatPrint" element={<BasquiatPrint />} />
                <Route
                  path="/BrittanyMurphyPrint"
                  element={<BrittanyMurphyPrint />}
                />
                <Route
                  path="/PrincessDianaPrint"
                  element={<PrincessDianaPrint />}
                />
                <Route path="/KobePrint" element={<KobePrint />} />
                <Route path="/LeftEyePrint" element={<LeftEyePrint />} />
                <Route
                  path="/MarilynMonroePrint"
                  element={<MarilynMonroePrint />}
                />
                <Route
                  path="/PrincessLeiaPrint"
                  element={<PrincessLeiaPrint />}
                />
                <Route path="/SelenePrint" element={<SelenePrint />} />
                <Route
                  path="/WhitneyHoustonPrint"
                  element={<WhitneyHoustonPrint />}
                />
                <Route
                  path="/FloatingInSpacePrint"
                  element={<FloatingInSpacePrint />}
                />
                <Route
                  path="/FloatingInSpacePrint"
                  element={<FloatingInSpacePrint />}
                />
                <Route path="/ParadoxPrint" element={<ParadoxPrint />} />
                <Route
                  path="/SelenePrincessPrint"
                  element={<SelenePrincessPrint />}
                />
                <Route
                  path="/GroundedForYouPrint"
                  element={<GroundedForYouPrint />}
                />
                <Route path="/TwinFlamePrint" element={<TwinFlamePrint />} />
                <Route path="/LeapsPrint" element={<LeapsPrint />} />
                <Route path="/RisksPrint" element={<RisksPrint />} />
                <Route
                  path="/TheRiseOfKobeArtPrint"
                  element={<TheRiseOfKobeArtPrint />}
                />
              </Routes>
            </CartProvider>
            {/* <Footer /> */}
          </div>
        </Router>
      </>
    );
  }
}

export default App;

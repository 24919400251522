import image023 from "../../../image/originals/gold/023.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "25_base",
      name: "Motherhood",
      image: image023,
      price: "$3900.00",
      thumbnails: [image023],
      description:
        "Acrylic and oil on canvas depicting the essence of motherhood.",
      projectUrl: "/Motherhood",
      sizes: ["40 X 50"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import { useEffect, useContext } from "react";
import { CartContext } from "../CartContext";

import backgroundImage from "../image/overlay/ai-generated-colorful-paint-splatters-against-transparent-background-free-png.png";

const Success = () => {
  const { cart, addToCart, removeFromCart } = useContext(CartContext);
  const { clearCart } = useContext(CartContext);

  useEffect(() => {
    removeFromCart(); // Clear the cart on page load
  }, [clearCart]);
  return (
    <div className="Content">
      <div
        className="upper-section-success"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundImage: `
          linear-gradient(to bottom, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0) 79%),
          url(${backgroundImage})`,
        }}
      >
        <section className="success-section">
          <h1>THANK YOU! 🎉</h1>
          <p>
            You will receive an order confirmation email shortly. <br /> <br />
            In the meantime, explore and get inspired by my exclusive collection
            of art and fashion!
          </p>
        </section>
      </div>
    </div>
  );
};

export default Success;

import jimmorrison from "../../../image/originals/hollywoodicons/jimmorrison.webp"; import jimmorrison2 from "../../../image/originals/hollywoodicons/jimmorrison2.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "62_base",
      name: "Jim Morrison",
      image: jimmorrison, // Main image
      price: "empty for now",
      thumbnails: [jimmorrison, jimmorrison2], // Other images (if available)
      description: "36 by 40” Acrylic on CanvasGlows in the dark",
      projectUrl: "/JimMorrison",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

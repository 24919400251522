import audreyhepburn from "../../../image/originals/hollywoodicons/audreyhepburn.webp"; import audreyhepburn2 from "../../../image/originals/hollywoodicons/audreyhepburn2.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "63_base",
      name: "Audrey Hepburn",
      image: audreyhepburn, // Main image
      price: "empty for now",
      thumbnails: [audreyhepburn, audreyhepburn2], // Other images (if available)
      description: "Overall size 24” x 30” triptych Acrylic on linen, Phosphorescence",
      projectUrl: "/AudreyHepburn",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import grounded from "../../../image/originals/quantum/GROUNDED.webp";
import grounded2 from "../../../image/originals/quantum/GROUNDED_BACK.webp";



import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "8_base",
      name: "Grounded For You Original",
      image: [grounded],
      price: "$8500.00",
      thumbnails: [grounded, grounded2], // Front and Back images (if available)
      description: "Acrylic and oil on canvas depicting a serene, grounded figure.",
      projectUrl: "/GroundedForYou",
      sizes: ["48 X 36"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import mother from "../../image/originals/gold/mother.webp";
// import viewoflaback from "../../image/originals/viewoflaback.webp";

import ItemNoSize from "./ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: 3,
      name: "Motherhood",
      image: mother,
      price: "$6000.00",
      thumbnails: [mother],
      description: '44" x 35" Acrylic and oil pastel on canvas',
      projectUrl: "",
      sizes: [""],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

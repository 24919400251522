import image011 from "../../../image/originals/gold/011.webp";
import image012 from "../../../image/originals/gold/012.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "28_base",
      name: "Mother and Infant with Child",
      image: image011,
      price: "$9250.00",
      thumbnails: [image011, image012],
      description:
        "Acrylic and oil on canvas depicting the bond between mother, infant, and child.",
      projectUrl: "/MotherAndInfantWithChild",
      sizes: ["48 X 36"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import image006 from "../../../image/originals/gold/006.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "35_base",
      name: "Queen and Her Pet",
      image: image006,
      price: "$3000.00",
      thumbnails: [image006],
      description:
        "Acrylic and oil on canvas showcasing a queen with her beloved pet.",
      projectUrl: "/QueenAndHerPet",
      sizes: ["36 X 48"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

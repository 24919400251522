import thirtyThree from "../../../image/originals/quantum/myopicmen.webp";
import thirtyFour from "../../../image/originals/quantum/myopicmen_back.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "11_base",
      name: "Myopic Men",
      image: [thirtyThree],
      price: "$5000.00",
      thumbnails: [thirtyThree, thirtyFour], // Front and Back images (if available)
      description:
        "Acrylic and oil on canvas depicting men with myopic vision.",
      projectUrl: "/MyopicMen",
      sizes: ["48 X 36"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import image024 from "../../../image/originals/gold/024.webp";
import image025 from "../../../image/originals/gold/025.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: 0,
      name: "Mother Playing with Infant and Child",
      image: image024,
      price: "$4900.00",
      thumbnails: [image024, image025], // Front and Back images
      description:
        "Acrylic and oil on canvas showing a playful moment between mother and children.",
      projectUrl: "/MotherPlayingWithInfantAndChild",
      sizes: ["48 X 36"],
      available: "sold",
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import leaps from "../../../image/originals/quantum/LEAPS.webp";
import leaps2 from "../../../image/originals/quantum/LEAPS_back.webp";

import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "4_base",
      name: "Leaps Original",
      image: [leaps],
      price: "$15000.00",
      thumbnails: [leaps, leaps2], // Front and Back images (if available)
      description: "Leaps into the unknown captured in acrylic and oil on canvas.",
      projectUrl: "/LeapsOriginal",
      sizes: ["48 X 48"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import shorts1 from "../../image/selene-collection/seleneshorts.webp";
import shorts2 from "../../image/selene-collection/seleneshorts2.webp";


import Items from "./Item";

const Shop = () => {
  const items = [
    {
      id: "105",
      name: "Selene Princess High Waisted Workout Shorts",
      image: shorts1,
      price: "$98.00",
      thumbnails: [shorts1, shorts2],
      description: "82/18 poly spandex one color print made in Los Angeles",
      projectUrl: "",
      sizes: ["Small/Medium"],
    },
  ];

  return <Items items={items} />;
};

export default Shop;
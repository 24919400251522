import image021 from "../../../image/originals/gold/021.webp";
import image022 from "../../../image/originals/gold/022.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: 0,
      name: "Deeper Than Blood",
      image: image021,
      price: "$4600.00",
      thumbnails: [image021, image022], // Front and Back images
      description:
        "Acrylic and oil on canvas capturing a bond stronger than blood.",
      projectUrl: "/DeeperThanBlood",
      sizes: ["48 X 36"],
      available: "sold",
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import romeo from "../../../image/originals/radical/free.webp";

import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "110_base",
      name: "Reena Rose",
      image: romeo,
      price: "$6000.00",
      thumbnails: [romeo],
      description:
        "Acrylic on wooden panel  Size: 36” x 48”  Inverted painting collaboration with Jerry Rodriguez  (Unstretched)",
      projectUrl: "",
      sizes: ["36 X 47"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import oladipo from "../../../image/originals/nba/oladipo.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: 0,
      name: "Vic Oladipo",
      image: oladipo, // Main image without .webp
      price: "empty for now",
      thumbnails: [oladipo], // Other images (if available)
      description: "36” x 36” ACRYLIC, GLITTER & FEATHER ON CANVAS",
      projectUrl: "/VicOladipo",
      sizes: ["blank"],
      available: "sold",
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

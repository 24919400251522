import liztaylor from "../../../image/originals/hollywoodicons/liztaylor.webp"; import liztaylor_2 from "../../../image/originals/hollywoodicons/liztaylor_2.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "57_base",
      name: "Elizabeth Taylor",
      image: liztaylor, // Main image
      price: "empty for now",
      thumbnails: [liztaylor, liztaylor_2], // Other images (if available)
      description: "12 by 16”Acrylic on canvas Glows in the dark(+ back of painting photo)",
      projectUrl: "/ElizabethTaylor",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

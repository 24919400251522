import aaliyah from "../../../image/originals/hollywoodicons/aaliyah.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "88_base",
      name: "Aaliyah Print",
      image: aaliyah, // Main image without .webp
      price: "empty for now",
      thumbnails: [aaliyah], // Other images (if available)
      description: 'Roughly 20" x 20" Museum Rag Paper.',
      projectUrl: "/AaliyahPrint",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import selene1 from "../../image/selene-collection/selenebottom.webp";
import selenedress from '../../image/selene-collection/selenedress1.webp'
import shorts1 from "../../image/selene-collection/seleneshorts.webp";
import top1 from "../../image/selene-collection/selenetop.webp";
import pants1 from '../../image/selene-collection/selenepants.webp'

import Collection from './Collection'

const SeleneCollection = () => {
  
  const title = "SELENE PRINCESS COLLECTION"

  const items = [
    {
      title: "Selene Princess Swimwear High Waisted Reversible Bikini Bottom",
      imageUrl: selene1,
      projectUrl: "/Shop",
    },
    {
      title: "Selene Princess High Waisted Workout Shorts",
      imageUrl: shorts1,
      projectUrl: "/SeleneShorts",
    },
    {
      title: "Selene Princess Workout Pants",
      imageUrl: pants1,
      projectUrl: "/SelenePants",
    },
    {
      title: "Selene Princess revesible Swimwear and Workout Top",
      imageUrl: top1,
      projectUrl: "/SeleneBikiniTop",
    },
    {
      title: "Selene Princess Dress",
      imageUrl: selenedress,
      projectUrl: "/SeleneDress",
    },
  ];

  return (
      <Collection items={items} title={title} />
  );
};

export default SeleneCollection;

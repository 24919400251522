import cradling1 from "../../../image/originals/gold/001.webp";
import cradling2 from "../../../image/originals/gold/002.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "29_base",
      name: "Mother Cradling Baby",
      image: cradling1,
      price: "$5000.00",
      thumbnails: [cradling1, cradling2],
      description: '13" x 15" Acrylic on canvas',
      projectUrl: "",
      sizes: ["36 X 47"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

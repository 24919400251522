import MichaelJordan23 from "../../../image/originals/nba/MichaelJordan23.webp"; import MichaelJordan23_back from "../../../image/originals/nba/MichaelJordan23_back.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "50_base",
      name: "Michael Jordan 23",
      image: MichaelJordan23, // Main image without .webp
      price: "$6,000",
      thumbnails: [MichaelJordan23, MichaelJordan23_back], // Other images (if available)
      description: "36” x 48” Acrylic, Glitter on Linen",
      projectUrl: "/MichaelJordan23",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

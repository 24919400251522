import MuhammadAli from "../../../image/originals/hollywoodicons/MuhammadAli.webp"; import MuhammadAli2 from "../../../image/originals/hollywoodicons/MuhammadAli2.webp"; import MuhammadAli3 from "../../../image/originals/hollywoodicons/MuhammadAli3.webp"; import MuhammadAli4 from "../../../image/originals/hollywoodicons/MuhammadAli4.webp"; 
import ItemWide from "../ItemWide";

const Shop = () => {
  const items = [
    {
      id: 0,
      name: "Muhammad Ali",
      image: MuhammadAli, // Main image
      price: "empty for now",
      thumbnails: [MuhammadAli, MuhammadAli2, MuhammadAli3, MuhammadAli4], // Other images (if available)
      description:
        "Overall size 12” x 32” diptych Acrylic on linen, Phosphorescence",
      projectUrl: "/MuhammadAli",
      sizes: ["blank"],
      available: "sold",
    },
  ];

  return <ItemWide items={items} />;
};

export default Shop;

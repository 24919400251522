import RISKS from "../../../image/originals/quantum/RISKS.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "94_base",
      name: "Risks Print",
      image: RISKS, // Main image without .webp
      price: "empty for now",
      thumbnails: [RISKS], // Other images (if available)
      description: "20 x 20” Museum Rag Paper.",
      projectUrl: "/RisksPrint",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

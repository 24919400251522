import Selene_Princess from "../../../image/originals/quantum/Selene_Princess.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "98_base",
      name: "Selene Princess Print",
      image: Selene_Princess, // Main image without .webp
      price: "empty for now",
      thumbnails: [Selene_Princess], // Other images (if available)
      description: "20 x 20” Museum Rag Paper.",
      projectUrl: "/SelenePrincessPrint",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

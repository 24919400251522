import liztaylor2 from "../../../image/originals/hollywoodicons/liztaylor2.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: 0,
      name: "Elizabeth Taylor 2",
      image: liztaylor2, // Main image
      price: "empty for now",
      thumbnails: [liztaylor2], // Other images (if available)
      description: '12" x 16" ACRYLIC & GLOWS IN THE DARK ON CANVAS',
      projectUrl: "/ElizabethTaylor2",
      sizes: ["blank"],
      available: "sold",
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import followyourgut from "../../../image/originals/quantum/followyourgut.webp";
import followyourgut_back from "../../../image/originals/quantum/followyourgut_back.webp";
import followyourgut_back2 from "../../../image/originals/quantum/followyourgut_back2.webp";

import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "5_base",
      name: "Follow Your Gut",
      image: [followyourgut],
      price: "$6000.00",
      thumbnails: [followyourgut, followyourgut_back, followyourgut_back2], // Front and Back images (if available)
      description: "Acrylic and oil on canvas exploring intuition and decision-making.",
      projectUrl: "/FollowYourGut",
      sizes: ["48 X 36"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import React, { useState, useEffect, useRef } from "react";
import Collection from "../../../components/collections/Collection";
import ProjectSample from "../../../project_samples/ProjectSamples";
import ProjectSampleMobile from "../../../project_samples/ProjectSamplesMobile";

// import diana from "../../../image/originals/PrincessDiana_590x.webp";
import bottom1 from "../../../image/selene-collection/bikiniBottom/bottom1.webp";
import purse from "../../../image/purses/purse1.webp";
import top from "../../../image/items/selene-top1.webp";
import rtronaut from "../../../image/items/stickers.webp";

import lazuliskirt1 from "../../../image/flirty-lazuli/lazuliskirt1.webp";
import lazulitop1 from "../../../image/flirty-lazuli/lazulitop1.webp";
import lazulitwopiece from "../../../image/flirty-lazuli/twopiece1.webp";

import selene1 from "../../../image/selene-collection/selenebottom.webp";
import selenedress from "../../../image/selene-collection/selenedress1.webp";
import shorts1 from "../../../image/selene-collection/seleneshorts.webp";
import top1 from "../../../image/selene-collection/selenetop.webp";
import pants1 from "../../../image/selene-collection/selenepants.webp";

import artronaut from "../../../image/stickers/artronaut1.webp";
import iam from "../../../image/stickers/iam1.webp";
import just from "../../../image/stickers/justa.webp";

import purse1 from "../../../image/purses/purses1.webp";

const Paintings = () => {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const intervalRef = useRef(null);
  const h2Ref = useRef(null); // Ref for h2 element
  

  const items = [
    {
      title: "'Without RT' Sticker",
      imageUrl: just,
      // projectUrl: "/RTronaut",
      projectUrl: "",
    },
    {
      title: "aRT Sticker Bundle",
      imageUrl: artronaut,
      projectUrl: "/StickerBundle",
    },
    {
      title: "Selene Princess Swimwear High Waisted Reversible Bikini Bottom",
      imageUrl: selene1,
      projectUrl: "/Shop",
    },
    {
      title: "Selene Princess High Waisted Workout Shorts",
      imageUrl: shorts1,
      projectUrl: "/SeleneShorts",
    },
    {
      title: "Selene Princess Workout Pants",
      imageUrl: pants1,
      projectUrl: "/SelenePants",
    },
    {
      title: "Selene Princess revesible Swimwear and Workout Top",
      imageUrl: top1,
      projectUrl: "/SeleneBikiniTop",
    },
    {
      title: "Selene Princess Dress",
      imageUrl: selenedress,
      projectUrl: "/SeleneDress",
    },
    // lazuli
    {
      title: "Flirty Lazuli Two Piece Dress",
      imageUrl: lazulitwopiece,
      projectUrl: "/FlirtyLazuliTwoPiece",
    },
    {
      title: "Flirty Lazuli Skirt",
      imageUrl: lazuliskirt1,
      projectUrl: "/FlirtyLazuliSkirt",
    },
    {
      title: "Flirty Lazuli Tops",
      imageUrl: lazulitop1,
      projectUrl: "/FlirtyLazuliTop",
    },

    // stickers
    {
      title: "All Star Purse",
      imageUrl: purse1,
      projectUrl: "/Purses",
    },
    {
      title: "aRTronaut Sticker",
      imageUrl: iam,
      projectUrl: "/RTronaut",
    },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    if (!isMobile) {
      const section = document.querySelector(".section-project-description");

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            section.classList.add("fade-out");
          } else {
            section.classList.remove("fade-out");
          }
        });
      });

      observer.observe(section);

      return () => observer.disconnect();
    }
  }, [isMobile]);

  return (
    <>
      <div className="Content">
        {isMobile ? (
          <>
            <section className="section-project-description-mobile">
              <h2
                className="project-title2"
                ref={h2Ref}
                data-value="EXCLUSIVE MERCH"
              >
                EXCLUSIVE MERCH
              </h2>
            </section>
            <div className="upper-section">
              <ProjectSampleMobile items={items} />
            </div>
          </>
        ) : (
          <>
            <section className="section-project-description">
              <h2
                className="project-title2"
                ref={h2Ref}
                data-value="EXCLUSIVE MERCH"
              >
                EXCLUSIVE MERCH
              </h2>
            </section>
            <div className="upper-section">
              <ProjectSample items={items} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Paintings;

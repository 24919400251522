import artro from "../../../image/stickers/artronaut1.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      // id: 0,
      id: "79_base",
      name: "“aRTronaut” sticker",
      image: [artro],
      price: "$4.99",
      thumbnails: [artro], // Front and Back images (if available)
      description: '3" x 3"',
      projectUrl: "/Artronaut",
      sizes: ["50 X 40"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

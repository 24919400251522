import reyna from "../../image/originals/gold/reyna1.webp";
import l from "../../image/originals/gold/L.webp";
import vibe from "../../image/originals/gold/vibe1.webp";
import mother from "../../image/originals/gold/mother.webp"

import image001 from "../../image/originals/gold/001.webp";
import image003 from "../../image/originals/gold/003.webp";
import image004 from "../../image/originals/gold/004.webp";
import image005 from "../../image/originals/gold/005.webp";
import image006 from "../../image/originals/gold/006.webp";
import image007 from "../../image/originals/gold/007.webp";
import image008 from "../../image/originals/gold/008.webp";
import image009 from "../../image/originals/gold/009.webp";
import image010 from "../../image/originals/gold/010.webp";
import image011 from "../../image/originals/gold/011.webp";
import image013 from "../../image/originals/gold/013.webp";
import image015 from "../../image/originals/gold/015.webp";
import image017 from "../../image/originals/gold/017.webp";
import image019 from "../../image/originals/gold/019.webp";
import image023 from "../../image/originals/gold/023.webp";
import image024 from "../../image/originals/gold/024.webp";
import image026 from "../../image/originals/gold/026.webp";
import image021 from "../../image/originals/gold/021.webp";



import Collection from "./Collection2";

const GoldCollection = () => {
  const title = "GOLD: Inspired by Pablo Picasso";

  const items = [
    {
      title: "El Rey y Reyna",
      imageUrl: reyna,
      projectUrl: "/Reyna",
    },
    {
      title: "'L'",
      imageUrl: l,
      projectUrl: "/L",
    },
    {
      title: "You're A Vibe",
      imageUrl: vibe,
      projectUrl: "/Vibe",
    },
    {
      imageUrl: image001,
      title: "Mother Cradling Baby",
      projectUrl: "/CradlingBaby",
    },
    {
      imageUrl: image003,
      title: "Growing Family",
      available: "out of stock",
      projectUrl: "/GrowingFamily",
    },
    {
      imageUrl: image004,
      title: "Pregnant with Newborn",
      projectUrl: "/Pregnant",
    },
    {
      imageUrl: image005,
      title: "Queen and Child",
      available: "out of stock",
      projectUrl: "/QueenChild",
    },
    {
      imageUrl: image006,
      title: "Queen and Her Pet",
      projectUrl: "/QueenPet",
    },
    {
      imageUrl: image007,
      title: "Mama y Bebe",
      projectUrl: "/MamaBebe",
    },
    {
      imageUrl: image008,
      title: "Queen and Her Pets",
      projectUrl: "/QueenAndPets",
    },
    {
      imageUrl: image009,
      title: "Queen Holding Toddler",
      available: "out of stock",
      projectUrl: "/QueenToddler",
    },
    {
      imageUrl: image010,
      title: "Young Love",
      projectUrl: "/YoungLove",
    },
    {
      imageUrl: image011,
      title: "Mother and Infant with Child",
      projectUrl: "/MotherInfant",
    },
    // { imageUrl: image012, title: "Mother and Infant with Child Back" },
    {
      imageUrl: image013,
      title: "Father Protecting Family",
      projectUrl: "/FatherFamily",
    },
    // { imageUrl: image014, title: "Father Protecting Family Back" },
    {
      imageUrl: image015,
      title: "Mother Cradling Child",
      available: "out of stock",
      projectUrl: "/MotherCradle",
    },
    // { imageUrl: image016, title: "Mother Cradling Child back" },
    {
      imageUrl: image017,
      title: "Father Lounging with Child",
      available: "out of stock",
      projectUrl: "/FatherLounging",
    },
    // { imageUrl: image018, title: "Father Lounging with Child Back" },
    {
      imageUrl: image019,
      title: "Mother and Child",
      projectUrl: "/MotherAndChild",
    },
    // { imageUrl: image020, title: "Mother and Child Back" },
    {
      imageUrl: image021,
      title: "Deeper Than Blood",
      available: "out of stock",
      projectUrl: "/Deeper",
    },
    // { imageUrl: image022, title: "Deeper Than Blood Back" },
    {
      imageUrl: image023,
      title: "Motherhood",
      projectUrl: "/Motherhood",
    },
    {
      imageUrl: image024,
      title: "Mother Playing with Infant and Child",
      available: "out of stock",
      projectUrl: "/MotherPlaying",
    },
    // { imageUrl: image025, title: "Mother Playing with Infant and Child Back" },
    {
      imageUrl: image026,
      title: "Familyhood",
      available: "out of stock",
      projectUrl: "/Familyhood",
    },
  ];


  return <Collection items={items} title={title} backgroundImage={image011} />;
};

export default GoldCollection;

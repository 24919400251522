import LebronLA from "../../../image/originals/nba/LebronLA.webp"; import LebronLA_back from "../../../image/originals/nba/LebronLA_back.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: 0,
      name: "LeBron LA",
      image: LebronLA, // Main image without .webp
      price: "empty for now",
      thumbnails: [LebronLA, LebronLA_back], // Other images (if available)
      description: '28" x 38" Acrylic Diamond Dust on CanvasGlow',
      projectUrl: "/LeBronLA",
      sizes: ["blank"],
      available: "sold",
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import foreverentangled from "../../../image/originals/quantum/foreverentangled.webp";import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "7_base",
      name: "Forever Entangled",
      image: [foreverentangled],
      price: "$16000.00",
      thumbnails: [foreverentangled], // Front and Back images (if available)
      description: "Acrylic and oil on canvas depicting intertwined destinies.",
      projectUrl: "/ForeverEntangled",
      sizes: ["48 X 36"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import selene_princess from "../../../image/originals/quantum/Selene_Princess.webp";
import selene_princess2 from "../../../image/originals/quantum/SELENE_BACK.webp";

import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "2_base",
      name: "Selene Princess Original",
      image: [selene_princess],
      price: "$15000.00",
      thumbnails: [selene_princess, selene_princess2], // Front and Back images (if available)
      description:
        "Acrylic and oil on canvas portraying Selene, the princess of the moon.",
      projectUrl: "/SelenePrincess",
      sizes: ["48 X 36"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

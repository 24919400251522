// import React, { useState, useEffect, useRef, useContext } from "react";
// import { Puff } from "react-loading-icons";
import twopiece from "../../image/flirty-lazuli/twopiecedress.webp";

import Items from "./Item";
// import { CartContext } from "../../CartContext"


const Shop = () => {

  const items = [
    {
      id: "101",
      name: "Flirty Lazuli Two Piece Dress",
      image: twopiece,
      price: "$265",
      thumbnails: [twopiece],
      description:
        "With black RT monogram on baby blue with neon seam.  95% poly 5% spandex (feels like silk on your skin)  Designed in UK, Made in Los Angeles.  The color may vary due to lighting on the images. The product images (without models) are closest to the true color of the product Hand wash cold and hang dry.",
      projectUrl: "",
      sizes: ["Small", "Medium", "Large", "XLarge"],
    },
  ];

  return (
    <Items items={items} />
  )
  }


export default Shop;

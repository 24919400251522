import romeo from "../../../image/originals/lockdown/03romeojuliet.webp";
import romeo1 from "../../../image/originals/lockdown/03romeojuliet_back.webp";

import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "76_base",
      name: "ROMEO + JULIET, 2020",
      image: romeo,
      price: "$6000.00",
      thumbnails: [romeo, romeo1],
      description: "ACRYLIC, OIL PASTEL ON CANVAS 27 IN X 36 IN",
      projectUrl: "",
      sizes: ["36 X 47"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import twinflame from "../../image/originals/quantum/twinFlame.webp";
import twinflame2 from "../../image/originals/quantum/twinflame2.webp"

import ItemNoSize from "./ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: 3,
      name: "Twin Flame",
      image: twinflame,
      price: "$6000.00",
      thumbnails: [twinflame, twinflame2],
      description: "20 x 20” Museum Rag Paper",
      projectUrl: "",
      sizes: ["36 X 47"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

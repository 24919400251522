import Mbappe from "../../../image/originals/futbol/Mbappe.webp"; import Mbppa2 from "../../../image/originals/futbol/Mbppa2.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "22_base",
      name: "KYLIAN MBAPPE",
      image: Mbappe, // Main image without .webp
      price: "$6000.00",
      thumbnails: [Mbappe, Mbppa2], // Other images (if available)
      description: "36 IN X 47 IN ACRYLIC, OIL PASTEL ON CANVAS",
      projectUrl: "/KylianMbappe",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

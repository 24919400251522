
import dress1 from "../../image/selene-collection/selenedress1.webp";
import dress2 from "../../image/selene-collection/selenedress2.webp";
import dress3 from "../../image/selene-collection/selenedress3.webp";
import dress4 from "../../image/selene-collection/selenedress4.webp";
import dress5 from "../../image/selene-collection/selenedress5.webp";

import Items from "./Item";

const Shop = () => {
  const items = [
    {
      id: "108",
      name: "Selene Princess Dress",
      image: dress1,
      price: "$375.00",
      thumbnails: [dress1, dress2, dress3, dress4, dress5],
      description: "90/10 poly spandex one color print made in Los Angeles",
      projectUrl: "",
      sizes: ["Small", "Medium"],
    },
  ];

  return <Items items={items} />;
};

export default Shop;
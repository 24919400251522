import image019 from "../../../image/originals/gold/019.webp";
import image020 from "../../../image/originals/gold/020.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "26_base",
      name: "Mother and Child",
      image: image019,
      price: "$9500.00",
      thumbnails: [image019, image020], // Front and Back images
      description:
        "Acrylic and oil on canvas depicting the nurturing bond between a mother and child.",
      projectUrl: "/MotherAndChild",
      sizes: ["40 X 50"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

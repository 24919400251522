import reyna1 from "../../../image/originals/gold/reyna1.webp";
import reyna2 from "../../../image/originals/gold/reyna2.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "38_base",
      name: "El Rey y Reyna",
      image: reyna1,
      price: "$9000.00",
      thumbnails: [reyna1, reyna2],
      description: "29” x 73 1/2” Acrylic, Oil Pastel on linen",
      projectUrl: "",
      sizes: ["36 X 47"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import four from "../../../image/originals/abstracts/4.webp";
import four_back from "../../../image/originals/abstracts/4_1.webp";import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: 0,
      name: "Divine Feminine",
      image: four, // Main image
      price: "$1200.00",
      thumbnails: [four, four_back], // Other images (if available)
      description:
        "Acrylic on canvas  Size: 18” x 37”  Personalized Quote Painted on the back of the piece.  (Unstretched)",
      projectUrl: "/DivineFeminine",
      sizes: ["36 X 48"],
      available: "sold",
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

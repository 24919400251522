import LEAPS from "../../../image/originals/quantum/LEAPS.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "95_base",
      name: "Leaps Print",
      image: LEAPS, // Main image without .webp
      price: "empty for now",
      thumbnails: [LEAPS], // Other images (if available)
      description: "20 x 20” Museum Rag Paper.",
      projectUrl: "/LeapsPrint",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

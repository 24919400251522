import Messi1 from "../../../image/originals/futbol/Messi1.webp"; import Messi2 from "../../../image/originals/futbol/Messi2.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "20_base",
      name: "LIONEL MESSI",
      image: Messi1, // Main image without .webp
      price: "$6000.00",
      thumbnails: [Messi1, Messi2], // Other images (if available)
      description: "27 in X 36 in  Acrylic oil pastel on canvas",
      projectUrl: "/LionelMessi",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

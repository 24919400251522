import rtronaut from "../../image/items/stickers.webp";

import Items from "./Item";

const Shop = () => {

  const items = [
    {
      id: 8,
      name: "“aRTronaut” Sticker",
      image: rtronaut,
      price: "$5.00",
      thumbnails: [rtronaut],
      description: '3" x 3"',
      projectUrl: "",
      sizes: ["Small", "Medium", "Large", "xlarge"],
    },
  ];
  

  return <Items items={items} />;
};

export default Shop;
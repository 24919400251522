import React, { useState, useEffect, useRef, useContext } from "react";
import { Puff } from "react-loading-icons";

// import lazulitop from "../../image/flirty-lazuli/lauzlitop.webp";

import { CartContext } from "../../CartContext";

const Shop = ({ items }) => {
  const { cart, addToCart, removeFromCart } = useContext(CartContext);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});
  const [selectedSize, setSelectedSize] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => setIsExpanded(!isExpanded);

  // useEffect(() => {
  //   const handleResize = () => setIsMobile(window.innerWidth < 768);
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  // useEffect(() => {
  //   if (!isMobile) {
  //     const section = document.querySelector(".section-project-description");
  //     if (section) {
  //       const observer = new IntersectionObserver((entries) => {
  //         entries.forEach((entry) => {
  //           section.classList.toggle("fade-out", entry.isIntersecting);
  //         });
  //       });
  //       observer.observe(section);
  //       return () => observer.disconnect();
  //     }
  //   }
  // }, [isMobile]);

  const Cart = () => {
    const cartRef = useRef(null);
    
    const toggleExpand = () => setIsExpanded(!isExpanded);

    const handleClickOutside = (event) => {
      if (cartRef.current && !cartRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };

    useEffect(() => {
      if (isExpanded) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isExpanded]);

    return (
      <div>
        {isMobile ? (
          <>
            {!isExpanded ? (
              <div className="fixed-bottom-right-container">
                <button
                  type="button"
                  className="fixed-bottom-right-button-mobile"
                  onClick={toggleExpand}
                >
                  Review Cart
                </button>
              </div>
            ) : (
              <div className="expanded-cart" ref={cartRef}>
                <div className="header">
                  <h5>Your Cart</h5>
                </div>
                <div className="cart-items">
                  {cart.map((item, index) => (
                    <React.Fragment key={`${item.id}-${item.size}-${index}`}>
                      <div className="cart-line-item" key={item.id}>
                        <p>Item: {item.name}</p>
                        <p>Qty: {item.quantity}</p>
                        <img
                          // loading="lazy"
                          src={item.image}
                          height={90}
                          width={90}
                          alt={item.name}
                        />
                        <button
                          style={{
                            transform: "scale(0.7)",
                            padding: "10px 20px",
                            border: "none",
                            borderRadius: "4px",
                            backgroundColor: "transparent",
                            color: "black",
                            height: "40px",
                            fontSize: "1.4rem",
                            cursor: "pointer",
                            transition: "background-color 0.3s ease",
                          }}
                          type="button"
                          onClick={() => removeFromCart(item.id, item.size)}
                        >
                          Remove
                        </button>
                      </div>
                      {"________________"}
                    </React.Fragment>
                  ))}
                  <form onSubmit={(e) => handleSubmit(e, cart)}>
                    <div className="cart-buttons-mobile">
                      <button className="checkout-buttons2" type="submit">
                        Checkout
                      </button>
                      <button
                        className="checkout-buttons2"
                        type="button"
                        onClick={toggleExpand}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {!isExpanded ? (
              <div className="fixed-bottom-right-container">
                <button
                  type="button"
                  className="fixed-bottom-right-button"
                  onClick={toggleExpand}
                >
                  Review Cart
                </button>
              </div>
            ) : (
              <div className="expanded-cart" ref={cartRef}>
                <div className="header">
                  <h5>Your Cart</h5>
                </div>
                <div className="cart-items">
                  {cart.map((item, index) => (
                    <React.Fragment key={`${item.id}-${item.size}-${index}`}>
                      <div className="cart-line-item" key={item.id}>
                        <p>Item: {item.name}</p>
                        <p>Qty: {item.quantity}</p>
                        <img
                          // loading="lazy"
                          src={item.image}
                          height={90}
                          width={90}
                          alt={item.name}
                        />
                        <button
                          style={{
                            transform: "scale(0.7)",
                            padding: "10px 20px",
                            border: "none",
                            borderRadius: "4px",
                            backgroundColor: "transparent",
                            color: "black",
                            height: "40px",
                            fontSize: "1.4rem",
                            cursor: "pointer",
                            transition: "background-color 0.3s ease",
                          }}
                          type="button"
                          onClick={() => removeFromCart(item.id, item.size)}
                        >
                          Remove
                        </button>
                      </div>
                      {"________________"}
                    </React.Fragment>
                  ))}
                  <form onSubmit={(e) => handleSubmit(e, cart)}>
                    <div className="cart-buttons">
                      <button className="checkout-buttons2" type="submit">
                        Checkout
                      </button>
                      <button
                        className="checkout-buttons2"
                        type="button"
                        onClick={toggleExpand}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  async function handleSubmit(event, cart) {
    event.preventDefault();
    console.log("Cart being sent:", JSON.stringify(cart, null, 2));
    setLoading(true);
    // Add a 1-second delay before resolving the promise
    await new Promise((resolve) => setTimeout(resolve, 1200));

    try {
      const response = await fetch(
        "https://stripe-server-reena-36b70709cadc.herokuapp.com/create-checkout-session",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ cart }),
        }
      );
      if (!response.ok) throw new Error(response.statusText);
      const session = await response.json();
      window.location.href = session.url;
    } catch (error) {
      console.error("Error", error);
      alert("There was an error processing your request. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  const selectThumbnail = (itemId, image) => {
    setSelectedImage((prevSelectedImage) => ({
      ...prevSelectedImage,
      [itemId]: image,
    }));
  };

  return (
    <>
      <div className="Content2">
        <div className="upper-section">
          {loading && (
            <>
              <Puff stroke="#0070f3" />
              <h2>Loading Checkout</h2>
            </>
          )}
        </div>
        {isMobile ? (
          <section className="sale-items-single-mobile">
            {items.map((item, index) => (
              <div
                className="project-sampler-item-mobile"
                key={`${item.id}-${item.size}-${index}`}
              >
                <form id="contact-form" className="contact-form" method="post">
                  <p className="shop-name">{item.name}</p>
                  <img
                    src={selectedImage[item.id] || item.image}
                    height={660}
                    width={520}
                    alt={item.name}
                    className="responsive-image"
                  />
                  <div className="thumbnails-container-div">
                    <p>{item.description}</p>
                    <div className="thumbnails-container">
                      {item.thumbnails.map((thumbnail, index) => (
                        <img
                          key={index}
                          src={thumbnail}
                          height={58}
                          width={58}
                          alt={`${item.name} thumbnail ${index + 1}`}
                          onClick={() => selectThumbnail(item.id, thumbnail)}
                          style={{
                            cursor: "pointer",
                            border:
                              selectedImage[item.id] === thumbnail
                                ? "2px solid #000"
                                : "none",
                          }}
                        />
                      ))}
                    </div>
                  </div>
                  {/* <div className="buttons-left-container">
                    <p className="size-label">SIZE</p>
                    <div className="radio-buttons-container">
                      {item.sizes.map((size) => (
                        <label key={size}>
                          <input
                            type="radio"
                            name={`size-${item.id}`}
                            value={size}
                            onChange={() => setSelectedSize(size)}
                          />
                          {size.charAt(0).toUpperCase() + size.slice(1)}
                        </label>
                      ))}
                    </div>
                  </div> */}
                  {/* {item.available === "available" ? (
                    <button
                      type="button"
                      onClick={() => {
                        addToCart(item, selectedSize);
                        toggleExpand();
                      }}
                    >
                      ADD TO CART
                    </button>
                  ) : (
                    <button disabled type="button">
                      OUT OF STOCK
                    </button>
                  )} */}
                  {item.available === "sold" ? (
                    <button
                      disabled
                      type="button"
                      onClick={() => addToCart(item, selectedSize)}
                    >
                      OUT OF STOCK
                    </button>
                  ) : (
                    <button
                      type="button"
                      // onClick={() => addToCart(item, selectedSize)}
                      onClick={() => {
                        addToCart(item, selectedSize);
                        toggleExpand();
                      }}
                      style={{
                        transform: "scale(0.7)",
                        padding: "10px 20px",
                        border: "none",
                        borderRadius: "4px",
                        backgroundColor: "#212121", // Dark background for available
                        color: "white", // White text for available
                        height: "60px",
                        width: "auto",
                        fontSize: "1.7rem",
                        cursor: "pointer", // Active cursor for available
                        transition: "background-color 0.3s ease", // Smooth hover effect
                      }}
                    >
                      ADD TO CART
                    </button>
                  )}
                </form>
              </div>
            ))}
            <Cart />
          </section>
        ) : (
          <section className="sale-items-single">
            {items.map((item) => (
              <div className="project-sampler-item" key={item.id}>
                <form
                  id="contact-form"
                  className="contact-form-wide"
                  method="post"
                >
                  <div className="buttons-left-container2">
                    <p className="shop-name">{item.name}</p>
                    <div className="cart-button">
                      {item.available === "sold" ? (
                        // If the item is sold, show the "OUT OF STOCK" button (disabled)
                        <button
                          disabled
                          style={{
                            transform: "scale(0.7)",
                            padding: "10px 20px",
                            border: "none",
                            borderRadius: "4px",
                            backgroundColor: "#555555", // Gray for out of stock
                            color: "#999999", // Lighter text color for out of stock
                            height: "60px",
                            width: "390px",
                            fontSize: "1.7rem",
                            cursor: "not-allowed", // Disabled cursor
                          }}
                        >
                          OUT OF STOCK
                        </button>
                      ) : (
                        // If the item is available, show the "ADD TO CART" button
                        <button
                          type="button"
                          onClick={() => {
                            addToCart(item);
                            toggleExpand();
                          }}
                          style={{
                            transform: "scale(0.7)",
                            padding: "10px 20px",
                            border: "none",
                            borderRadius: "4px",
                            backgroundColor: "#212121", // Dark background for available
                            color: "white", // White text for available
                            height: "60px",
                            width: "390px",
                            fontSize: "1.7rem",
                            cursor: "pointer", // Active cursor for available
                            transition: "background-color 0.3s ease", // Smooth hover effect
                          }}
                        >
                          ADD TO CART
                        </button>
                      )}
                    </div>
                    <div className="thumbnails-container-div">
                      <div className="thumbnails-container">
                        {item.thumbnails.map((thumbnail, index) => (
                          <img
                            key={index}
                            src={thumbnail}
                            height={90}
                            width={90}
                            alt={`${item.name} thumbnail ${index + 1}`}
                            onClick={() => selectThumbnail(item.id, thumbnail)}
                            style={{
                              cursor: "pointer",
                              border:
                                selectedImage[item.id] === thumbnail
                                  ? "2px solid #000"
                                  : "none",
                            }}
                          />
                        ))}
                      </div>
                    </div>
                    <p className="shop-description">{item.description}</p>
                  </div>
                  <img
                    src={selectedImage[item.id] || item.image}
                    height={760}
                    width={620}
                    alt={item.name}
                    className="responsive-image"
                  />
                  {/* <p className="shop-description">{item.description}</p> */}
                </form>
              </div>
            ))}
            <Cart />
          </section>
        )}
      </div>
    </>
  );
};

export default Shop;

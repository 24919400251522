import three from "../../../image/originals/abstracts/3.webp";
import three_back from "../../../image/originals/abstracts/3_1.webp";import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "40_base",
      name: "Cancel Culture",
      image: three, // Main image
      price: "$2500.00",
      thumbnails: [three, three_back], // Other images (if available)
      description: "Acrylic on Canvas  Size: 34” x 36”  Personalized Quote Painted on the back of the piece.  (Unstretched)",
      projectUrl: "/CancelCulture",
      sizes: ["48 X 48"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import image007 from "../../../image/originals/gold/007.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "33_base",
      name: "Mama y Bebe",
      image: image007,
      price: "$3000.00",
      thumbnails: [image007],
      description:
        "Acrylic and oil on canvas of a tender moment between mother and baby.",
      projectUrl: "/MamayBebe",
      sizes: ["40 X 50"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import lebronla from '../../image/originals/nba/LebronLA.webp'
import stephencurry from "../../image/originals/nba/steph_curry.webp";
import jordan23 from '../../image/originals/nba/MichaelJordan23.webp'
import oladipo from "../../image/originals/nba/oladipo.webp";
import shaqfu from "../../image/originals/nba/shaq.webp";
import traeyoung from "../../image/originals/nba/traeyoung.webp";
import michael from "../../image/originals/nba/michael.webp";
import russell from "../../image/originals/nba/russell.webp";
import lebronlakers from "../../image/originals/nba/lebronlakers.webp";
import lebroncleveland from '../../image/originals/nba/LebronCleveland.webp'
import kyrie from "../../image/originals/nba/kyrie.webp";
import durant from "../../image/originals/nba/durant.webp";
import kawahi from "../../image/originals/nba/kawahi.webp";
import harden from "../../image/originals/nba/harden.webp";
import giannis from "../../image/originals/nba/giannis.webp";
import anthony from "../../image/originals/nba/anthony.webp";



import Collection from "./Collection2";

const NBACollection = () => {
  const title = "NBA ALL STARS";

  const items = [
    {
      title: "Stephen Curry",
      imageUrl: stephencurry,
      available: "out of stock",
      projectUrl: "/StephenCurry",
    },
    {
      title: "LeBron LA",
      imageUrl: lebronla,
      available: "out of stock",
      projectUrl: "/LeBronLA",
    },
    {
      title: "Michael Jordan 23",
      imageUrl: jordan23,
      projectUrl: "/MichaelJordan23",
    },
    // {
    //   title: "Vic Oladipo",
    //   imageUrl: oladipo,
    // available: "out of stock",
    //   projectUrl: "/VicOladipo",
    // },
    {
      title: "Shaq",
      imageUrl: shaqfu,
      projectUrl: "/Shaq",
    },
    // {
    //   title: "Trae Young 2020",
    //   imageUrl: traeyoung,
    // available: "out of stock",
    //   projectUrl: "/TraeYoung2020",
    // },
    {
      title: "Michael Jordan",
      imageUrl: michael,
      projectUrl: "/MichaelJordan",
    },
    {
      title: "Russell Westbrook",
      imageUrl: russell,
      available: "out of stock",
      projectUrl: "/RussellWestbrook",
    },
    {
      title: "LeBron Lakers",
      imageUrl: lebronlakers,
      projectUrl: "/LeBronLakers",
    },
    // {
    //   title: "LeBron Cleveland",
    //   imageUrl: lebroncleveland,
    // available: "out of stock",
    //   projectUrl: "/LeBronCleveland",
    // },
    {
      title: "Kyrie Irving",
      imageUrl: kyrie,
      projectUrl: "/KyrieIrving",
    },
    // {
    //   title: "Kevin Durant",
    //   imageUrl: durant,
    // available: "out of stock",
    //   projectUrl: "/KevinDurant",
    // },
    {
      title: "Kawhi Leonard",
      imageUrl: kawahi,
      projectUrl: "/KawhiLeonard",
    },
    // {
    //   title: "James Harden",
    //   imageUrl: harden,
    // available: "out of stock",
    //   projectUrl: "/JamesHarden",
    // },
    {
      title: "Giannis",
      imageUrl: giannis,
      projectUrl: "/Giannis",
    },
    {
      title: "Anthony Davis",
      imageUrl: anthony,
      projectUrl: "/AnthonyDavis",
    },
  ];
  

  return <Collection items={items} title={title} backgroundImage={lebronlakers} />;
};

export default NBACollection;

import russell from "../../../image/originals/nba/russell.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: 0,
      name: "Russell Westbrook",
      image: russell, // Main image without .webp
      price: "empty for now",
      thumbnails: [russell], // Other images (if available)
      description: "36” X 36” ACRYLIC & GLITTER ON CANVAS",
      projectUrl: "/RussellWestbrook",
      sizes: ["blank"],
      available: "sold",
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

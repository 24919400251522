import freshprince from "../../../image/originals/hollywoodicons/freshprince.webp"; import freshprince2 from "../../../image/originals/hollywoodicons/freshprince2.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "64_base",
      name: "Fresh Prince",
      image: freshprince, // Main image
      price: "empty for now",
      thumbnails: [freshprince, freshprince2], // Other images (if available)
      description: "40” x 29“ Acrylic, Glitter with Phosphorescent paint on canvas",
      projectUrl: "/FreshPrince",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import giannis from "../../../image/originals/nba/giannis.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "44_base",
      name: "Giannis",
      image: giannis, // Main image without .webp
      price: "$5500.00",
      thumbnails: [giannis], // Other images (if available)
      description: "36” X 36” ACRYLIC & GLITTER ON CANVAS",
      projectUrl: "/Giannis",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

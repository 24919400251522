import princessleia from "../../../image/originals/hollywoodicons/princessleia.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "56_base",
      name: "Princess Leia Original",
      image: princessleia, // Main image
      price: "empty for now",
      thumbnails: [princessleia], // Other images (if available)
      description: "30x30Acrylic, Glitter on Linen, Glows",
      projectUrl: "/PrincessLeiaOriginal",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import six from "../../../image/originals/abstracts/6.webp";
import six_back from "../../../image/originals/abstracts/6_1.webp";import ItemWide from "../ItemWide";

const Shop = () => {
  const items = [
    {
      id: 0,
      name: "WWW",
      image: six, // Main image
      price: "$1800.00",
      thumbnails: [six, six_back], // Other images (if available)
      description:
        "Acrylic and oil pastels on canvas  Size: 38” x 73”  Personalized Quote Painted on the back of the piece.  (Unstretched)",
      projectUrl: "/WWW",
      sizes: ["40 X 60"],
      available: "sold",
    },
  ];

  return <ItemWide items={items} />;
};

export default Shop;

import image003 from "../../../image/originals/gold/003.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: 0,
      name: "Growing Family",
      image: image003,
      price: "$5000.00",
      thumbnails: [image003],
      description: "Acrylic and oil on canvas showing the growth of a family.",
      projectUrl: "/GrowingFamily",
      sizes: ["36 X 48"],
      available: "sold"
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import React, { useState, useEffect, useRef } from "react";
import ProjectSample from "../../../project_samples/ProjectSamples2";
import ProjectSampleMobile from "../../../project_samples/ProjectSamplesMobile";

import floatingInSpacePrintImage from "../../../image/originals/quantum/floating.webp";
import paradoxPrintImage from "../../../image/originals/quantum/paradox.webp";
import selenePrincessPrintImage from "../../../image/originals/quantum/Selene_Princess.webp";
import groundedForYouPrintImage from "../../../image/originals/quantum/GROUNDED.webp";
import twinFlamePrintImage from "../../../image/originals/quantum/twinFlame.webp";
import leapsPrintImage from "../../../image/originals/quantum/LEAPS.webp";
import risksPrintImage from "../../../image/originals/quantum/RISKS.webp";

const Paintings = () => {
  const items = [
    {
      title: "Floating in Space Print",
      imageUrl: floatingInSpacePrintImage,
      projectUrl: "/FloatingInSpacePrint",
    },
    {
      title: "Paradox Print",
      imageUrl: paradoxPrintImage,
      projectUrl: "/ParadoxPrint",
    },
    {
      title: "Selene Princess Print",
      imageUrl: selenePrincessPrintImage,
      projectUrl: "/SelenePrincessPrint",
    },
    {
      title: "Grounded For You Print",
      imageUrl: groundedForYouPrintImage,
      projectUrl: "/GroundedForYouPrint",
    },
    {
      title: "Twin Flame Print",
      imageUrl: twinFlamePrintImage,
      projectUrl: "/TwinFlamePrint",
    },
    {
      title: "Leaps Print",
      imageUrl: leapsPrintImage,
      projectUrl: "/LeapsPrint",
    },
    {
      title: "Risks Print",
      imageUrl: risksPrintImage,
      projectUrl: "/RisksPrint",
    },
  ];

  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const intervalRef = useRef(null);
  const h2Ref = useRef(null); // Ref for h2 element

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    if (!isMobile) {
      const section = document.querySelector(".section-project-description");

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            section.classList.add("fade-out");
          } else {
            section.classList.remove("fade-out");
          }
        });
      });

      observer.observe(section);

      return () => observer.disconnect();
    }
  }, [isMobile]);

  return (
    <>
      <div className="Content">
        {isMobile ? (
          <>
            <section className="section-project-description-mobile">
              <h2
                className="project-title2"
                ref={h2Ref}
                data-value="QUANTUM ENTANGLEMENT PRINTS"
              >
                QUANTUM ENTANGLEMENT PRINTS
              </h2>
            </section>
            <div className="upper-section">
              <ProjectSampleMobile items={items} />
            </div>
          </>
        ) : (
          <>
            <section className="section-project-description">
              <h2
                className="project-title2"
                ref={h2Ref}
                data-value="QUANTUM ENTANGLEMENT PRINTS"
              >
                QUANTUM ENTANGLEMENT PRINTS
              </h2>
            </section>
            <div className="upper-section">
              <ProjectSample items={items} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Paintings;

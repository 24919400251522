import React, { useState, useEffect, useRef } from "react";
import ProjectSample from "../../../project_samples/ProjectSamples2";
import ProjectSampleMobile from "../../../project_samples/ProjectSamplesMobile";

import riseOfKobePrintImage from "../../../image/originals/hollywoodicons/kobeprint.webp";
import whitneyHoustonPrintImage from "../../../image/originals/hollywoodicons/whitney.webp";
import princessLeiaPrintImage from "../../../image/originals/hollywoodicons/princessleia.webp";
import leftEyePrintImage from "../../../image/originals/hollywoodicons/lisalefteye.webp";
import audreyBreakfastAtTiffanysPrintImage from "../../../image/originals/hollywoodicons/audreybreakfastprint.webp";
import aaliyahPrintImage from "../../../image/originals/hollywoodicons/aaliyah.webp";
import selenePrintImage from "../../../image/originals/quantum/seleneprint.webp";
import audreyHepburnPrintImage from "../../../image/originals/hollywoodicons/audreyprint.webp";
import marilynMonroePrintImage from "../../../image/originals/hollywoodicons/marilyn.jpg";
import brittanyMurphyPrintImage from "../../../image/originals/hollywoodicons/brittanymurphy.webp";
import princessDianaPrintImage from "../../../image/originals/hollywoodicons/diana.webp";
import basquiatPrintImage from "../../../image/originals/hollywoodicons/basquiatprint.webp";

const Paintings = () => {
  const items = [
    {
      title: "The Rise of Kobe Art Print",
      imageUrl: riseOfKobePrintImage,
      projectUrl: "/KobePrint", // updated to match the route
    },
    {
      title: "Whitney Houston Print",
      imageUrl: whitneyHoustonPrintImage,
      projectUrl: "/WhitneyHoustonPrint", // no change
    },
    {
      title: "Princess Leia Print",
      imageUrl: princessLeiaPrintImage,
      projectUrl: "/PrincessLeiaPrint", // no change
    },
    {
      title: "Left Eye Print",
      imageUrl: leftEyePrintImage,
      projectUrl: "/LeftEyePrint", // no change
    },
    {
      title: "Audrey Hepburn (Breakfast at Tiffanys) Print",
      imageUrl: audreyBreakfastAtTiffanysPrintImage,
      projectUrl: "/AudreyHepburnBreakfastPrint", // route commented out, so match it here
    },
    {
      title: "Aaliyah Print",
      imageUrl: aaliyahPrintImage,
      projectUrl: "/AaliyahPrint", // no change
    },
    {
      title: "Selene Print",
      imageUrl: selenePrintImage,
      projectUrl: "/SelenePrint", // no change
    },
    {
      title: "Audrey Hepburn Print",
      imageUrl: audreyHepburnPrintImage,
      projectUrl: "/AudreyHepburnPrint", // no change
    },
    {
      title: "Marilyn Monroe Print",
      imageUrl: marilynMonroePrintImage,
      projectUrl: "/MarilynMonroePrint", // no change
    },
    {
      title: "Brittany Murphy Print",
      imageUrl: brittanyMurphyPrintImage,
      projectUrl: "/BrittanyMurphyPrint", // no change
    },
    {
      title: "Princess Diana Print",
      imageUrl: princessDianaPrintImage,
      projectUrl: "/PrincessDianaPrint", // route commented out, match the route here
    },
    {
      title: "Jean-Michel Basquiat Print",
      imageUrl: basquiatPrintImage,
      projectUrl: "/BasquiatPrint", // route commented out, match the route here
    },
  ];


  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const intervalRef = useRef(null);
  const h2Ref = useRef(null); // Ref for h2 element

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    if (!isMobile) {
      const section = document.querySelector(".section-project-description");

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            section.classList.add("fade-out");
          } else {
            section.classList.remove("fade-out");
          }
        });
      });

      observer.observe(section);

      return () => observer.disconnect();
    }
  }, [isMobile]);

  return (
    <>
      <div className="Content">
        {isMobile ? (
          <>
            <section className="section-project-description-mobile">
              <h2
                className="project-title2"
                ref={h2Ref}
                data-value="HOLLYWOOD ICON PRINTS"
              >
                HOLLYWOOD ICON PRINTS
              </h2>
            </section>
            <div className="upper-section">
              <ProjectSampleMobile items={items} />
            </div>
          </>
        ) : (
          <>
            <section className="section-project-description">
              <h2
                className="project-title2"
                ref={h2Ref}
                data-value="HOLLYWOOD ICON PRINTS"
              >
                HOLLYWOOD ICON PRINTS
              </h2>
            </section>
            <div className="upper-section">
              <ProjectSample items={items} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Paintings;

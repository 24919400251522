import image013 from "../../../image/originals/gold/013.webp";
import image014 from "../../../image/originals/gold/014.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "30_base",
      name: "Father Protecting Family",
      image: image013,
      price: "$9250.00",
      thumbnails: [image013, image014],
      description:
        "Acrylic and oil on canvas showing the protective nature of a father.",
      projectUrl: "/FatherProtectingFamily",
      sizes: ["48 X 36"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import marleyjammin from "../../../image/originals/hollywoodicons/marleyjammin.webp"; import marleyjammin2 from "../../../image/originals/hollywoodicons/marleyjammin2.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "73_base",
      name: "Bob Marley Jammin",
      image: marleyjammin, // Main image
      price: "empty for now",
      thumbnails: [marleyjammin, marleyjammin2], // Other images (if available)
      description: "24” x 36” Acrylic, Oil Pastel on linen",
      projectUrl: "/BobMarleyJammin",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import TigerWoods from "../../../image/originals/hollywoodicons/TigerWoods.webp"; import tigerwoods2 from "../../../image/originals/hollywoodicons/tigerwoods2.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "54_base",
      name: "Tiger Woods",
      image: TigerWoods, // Main image
      price: "$2300.00",
      thumbnails: [TigerWoods, tigerwoods2], // Other images (if available)
      description: "24” x 30” Acrylic and Oil Pastel on Canvas Quote Behind painting: “Golf is a great way for someone to learn discipline, responsibility, and sportsmanship.”",
      projectUrl: "/TigerWoods",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

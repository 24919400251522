import VirgilAbloh from "../../../image/originals/hollywoodicons/VirgilAbloh.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "53_base",
      name: "Virgil Abloh",
      image: VirgilAbloh, // Main image
      price: "$",
      thumbnails: [VirgilAbloh], // Other images (if available)
      description: '16" x 20" Acrylic & Diamond dust on Canvas *Glow Quote behind the painting: "Art is made in hindsight"',
      projectUrl: "/VirgilAbloh",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import React, { useState, useEffect, useRef } from "react";
import ProjectSample from "../../../project_samples/ProjectSamples2";
import ProjectSampleMobile from "../../../project_samples/ProjectSamplesMobile";

import radical from "../../../image/originals/radical/free.webp";

import floating from "../../../image/originals/quantum/floating.webp";
import twin from "../../../image/originals/quantum/twinFlame.webp";
import risk from "../../../image/originals/quantum/RISKS.webp";

import AD_2020 from "../../../image/originals/nba/anthony.webp";
import Trae_Young_2020 from "../../../image/originals/nba/traeyoung.webp";
import no_caption_007 from "../../../image/originals/nba/michael.webp";
import Steph_Curry_the_Three_Point_King from "../../../image/originals/nba/steph_curry.webp";
import no_caption_012 from "../../../image/originals/nba/shaq.webp";
import no_caption_013 from "../../../image/originals/nba/harden.webp";
import no_caption_014 from "../../../image/originals/nba/durant.webp";
import no_caption_015 from "../../../image/originals/nba/kyrie.webp";
import no_caption_016 from "../../../image/originals/nba/russell.webp";
import Kawhi_Leonard_2020 from "../../../image/originals/nba/kawahi.webp";
import no_caption_020 from "../../../image/originals/nba/harden.webp";
import no_caption_021 from "../../../image/originals/nba/oladipo.webp";
import Le_Bron_Throwdown from "../../../image/originals/nba/lebronlakers.webp";

import lebronla from "../../../image/originals/nba/LebronLA.webp";
import jordan from "../../../image/originals/nba/MichaelJordan23.webp";
import lebroncleveland from "../../../image/originals/nba/LebronCleveland.webp";

import viewla from "../../../image/originals/lockdown/01viewla.webp";
import googlethis from "../../../image/originals/lockdown/02googlethis.webp";
import romeoJuliet from "../../../image/originals/lockdown/03romeojuliet.webp";
import washingHands from "../../../image/originals/lockdown/04washinghands.webp";

import frida from "../../../image/originals/hollywoodicons/frida.webp";
import bobMarleyDreads from "../../../image/originals/hollywoodicons/marleydreads.webp"; // Corrected path
import basquiatDetails from "../../../image/originals/hollywoodicons/basq.webp"; // Corrected path
import bobMarleyJammin from "../../../image/originals/hollywoodicons/marleyjammin.webp";
import freshPrince from "../../../image/originals/hollywoodicons/freshprince.webp";
import princessLeiaOriginal from "../../../image/originals/hollywoodicons/princessleia.webp";
import whitneyHoustonOriginal from "../../../image/originals/hollywoodicons/whitney.webp";
import davidBowie from "../../../image/originals/hollywoodicons/bowie.webp";
import princessDianaOriginal from "../../../image/originals/hollywoodicons/diana.webp"; // Corrected path
import elizabethTaylor from "../../../image/originals/hollywoodicons/liztaylor.webp";
import jimMorrison from "../../../image/originals/hollywoodicons/jimmorrison.webp";
import bruceLee from "../../../image/originals/hollywoodicons/brucelee.webp";
import michaelJacksonOriginal from "../../../image/originals/hollywoodicons/michaeljackson.webp";
import jDilla from "../../../image/originals/hollywoodicons/jaydee.webp";
import sophiaLoren from "../../../image/originals/hollywoodicons/SophiaLoren.webp";
import audreyHepburn from "../../../image/originals/hollywoodicons/audreyhepburn.webp"; // Corrected path
import virgilAbloh from "../../../image/originals/hollywoodicons/VirgilAbloh.webp";
import leftEyeOriginal from "../../../image/originals/hollywoodicons/lisalefteye.webp";
import prince from "../../../image/originals/hollywoodicons/Prince.webp";
import tigerWoods from "../../../image/originals/hollywoodicons/TigerWoods.webp";
import elonMusk from "../../../image/originals/hollywoodicons/musk.webp";
import elizabethTaylor2 from "../../../image/originals/hollywoodicons/liztaylor2.webp";
import princessDiana from "../../../image/originals/hollywoodicons/princessdiana2.webp";
import macMiller from "../../../image/originals/hollywoodicons/macmiller.webp";
import muhammadAli from "../../../image/originals/hollywoodicons/MuhammadAli.webp";
import markey from "../../../image/originals/hollywoodicons/bobmarley.webp";

import l from "../../../image/originals/gold/L.webp";
import vibe from "../../../image/originals/gold/vibe1.webp";
import mother from "../../../image/originals/gold/mother.webp";

import diana from "../../../image/originals/hollywoodicons/PrincessDiana_590x.webp";
import twinflame from "../../../image/originals/quantum/twinFlame.webp";
import brittany from "../../../image/originals/hollywoodicons/brittanymurphy.webp";
import viewofla from "../../../image/originals/viewofla.webp";

import one from "../../../image/originals/abstracts/1.webp"; //Fool Them Not Me
import two from "../../../image/originals/abstracts/2.webp"; //Intentions
import three from "../../../image/originals/abstracts/3.webp"; //Cancel culture
import four from "../../../image/originals/abstracts/4.webp"; //Divine Feminine
import five from "../../../image/originals/abstracts/5.webp"; // It Came In A Dream
import six from "../../../image/originals/abstracts/6.webp"; // WWW

import HarryKane from "../../../image/originals/futbol/Harrykane.webp";
import KylianMbappe from "../../../image/originals/futbol/Mbappe.webp";
import Messi1 from "../../../image/originals/futbol/Messi1.webp";
import MoSalah from "../../../image/originals/futbol/MoSalah.webp";
import Neymar1 from "../../../image/originals/futbol/Neymar1.webp";
import HarryKane2 from "../../../image/originals/futbol/HaryKane2.webp";
import Mbappe2 from "../../../image/originals/futbol/Mbppa2.webp";
import Messi2 from "../../../image/originals/futbol/Messi2.webp";
import MoSalah2 from "../../../image/originals/futbol/MoSalah2.webp";
import Neymar2 from "../../../image/originals/futbol/Neymar2.webp";

const Paintings = () => {
  const items = [
    {
      title: "VIEW OF LA (SELF-PORTRAIT), 2020",
      imageUrl: viewofla,
      projectUrl: "/ViewOfLa",
    },
    {
      title: "Kylian Mbappe",
      imageUrl: KylianMbappe,
      projectUrl: "/KylianMbappe", // This route exists
    },
    {
      title: "Twin Flame",
      imageUrl: twinflame,
      projectUrl: "/TwinFlameOriginal",
    },
    {
      title: "Cancel Culture",
      imageUrl: three,
      projectUrl: "/CancelCulture",
    },
    {
      title: "Princess Diana",
      imageUrl: princessDiana,
      projectUrl: "/PrincessDiana",
    },
    {
      title: "Divine Feminine",
      imageUrl: four,
      projectUrl: "/DivineFeminine",
    },
    {
      title: "David Bowie",
      imageUrl: davidBowie,
      projectUrl: "/DavidBowie",
    },
    {
      title: "Risks Original",
      imageUrl: risk,
      projectUrl: "/RisksOriginal",
    },
    {
      title: "LeBron Cleveland",
      imageUrl: lebroncleveland,
      projectUrl: "/LeBronCleveland",
    },
    {
      title: "WWW",
      imageUrl: six,
      projectUrl: "/WWW",
    },
    {
      title: "Harry Kane",
      imageUrl: HarryKane,
      projectUrl: "/HarryKane",
    },
    {
      title: "Floating In Space",
      imageUrl: floating,
      projectUrl: "/FloatingInSpace",
    },
    {
      title: "You're A Vibe",
      imageUrl: vibe,
      projectUrl: "/Vibe",
    },
    {
      title: "It Came In A Dream",
      imageUrl: five,
      projectUrl: "/ItCameInADream",
    },
    {
      title: "Whitney Houston Original",
      imageUrl: whitneyHoustonOriginal,
      projectUrl: "/WhitneyHoustonOriginal",
    },
    {
      title: "Neymar",
      imageUrl: Neymar1,
      projectUrl: "/Neymar",
    },
    {
      title: "Sophia Loren",
      imageUrl: sophiaLoren,
      projectUrl: "/SophiaLoren",
    },
    {
      title: "Fool Them Not Me",
      imageUrl: one,
      projectUrl: "/FoolThemNotMe",
    },
    {
      title: "Kyrie Irving",
      imageUrl: no_caption_015,
      projectUrl: "/KyrieIrving",
    },
    {
      title: "Frida",
      imageUrl: frida,
      projectUrl: "/Frida",
    },
    {
      title: "Twin Flame Original",
      imageUrl: twin,
      projectUrl: "/TwinFlameOriginal",
    },
    {
      title: "Michael Jackson Original",
      imageUrl: michaelJacksonOriginal,
      projectUrl: "/MichaelJacksonOriginal",
    },
    {
      title: "Bob Marley Dreads",
      imageUrl: bobMarleyDreads,
      projectUrl: "/BobMarleyDreads",
    },
    {
      title: "Mohamed Salah",
      imageUrl: MoSalah,
      projectUrl: "/MohamedSalah",
    },
    {
      title: "Elon Musk",
      imageUrl: elonMusk,
      projectUrl: "/ElonMusk",
    },
    {
      title: "Mac Miller",
      imageUrl: macMiller,
      projectUrl: "/MacMiller",
    },
    {
      title: "James Harden",
      imageUrl: no_caption_020,
      projectUrl: "/JamesHarden",
    },
    {
      title: "LeBron LA",
      imageUrl: lebronla,
      projectUrl: "/LeBronLA",
    },
    {
      title: "Bob Marley Jammin",
      imageUrl: bobMarleyJammin,
      projectUrl: "/BobMarleyJammin",
    },
    {
      title: "Anthony Davis",
      imageUrl: AD_2020,
      projectUrl: "/AnthonyDavis",
    },
    {
      title: "Princess Leia Original",
      imageUrl: princessLeiaOriginal,
      projectUrl: "/PrincessLeiaOriginal",
    },
    {
      title: "Basquiat Details",
      imageUrl: basquiatDetails,
      projectUrl: "/BasquiatDetails",
    },
    {
      title: "Michael Jordan 23",
      imageUrl: jordan,
      projectUrl: "/MichaelJordan23",
    },
    {
      title: "Virgil Abloh",
      imageUrl: virgilAbloh,
      projectUrl: "/VirgilAbloh",
    },
    {
      title: "Giannis",
      imageUrl: no_caption_013,
      projectUrl: "/Giannis",
    },
    {
      title: "Motherhood",
      imageUrl: mother,
      projectUrl: "/Motherhood",
    },
    {
      title: "Google This",
      imageUrl: googlethis,
      projectUrl: "/GoogleThis",
    },
    {
      title: "Tiger Woods",
      imageUrl: tigerWoods,
      projectUrl: "/TigerWoods",
    },
    {
      title: "Intentions",
      imageUrl: two,
      projectUrl: "/Intentions",
    },
    {
      title: "Bruce Lee",
      imageUrl: bruceLee,
      projectUrl: "/BruceLee",
    },
    {
      title: "Princess Diana Original",
      imageUrl: princessDianaOriginal,
      projectUrl: "/PrincessDianaOriginal",
    },
    {
      title: "Shaq",
      imageUrl: no_caption_012,
      projectUrl: "/Shaq",
    },
    {
      title: "Reena Rose",
      imageUrl: radical,
      projectUrl: "/ReenaRose",
    },
    {
      title: "Romeo & Juliet",
      imageUrl: romeoJuliet,
      projectUrl: "/RomeoAndJuliet",
    },
    {
      title: "Russell Westbrook",
      imageUrl: no_caption_016,
      projectUrl: "/RussellWestbrook",
    },
    {
      title: "Trae Young 2020",
      imageUrl: Trae_Young_2020,
      projectUrl: "/TraeYoung2020",
    },
    {
      title: "Jim Morrison",
      imageUrl: jimMorrison,
      projectUrl: "/JimMorrison",
    },
    {
      title: "Fresh Prince",
      imageUrl: freshPrince,
      projectUrl: "/FreshPrince",
    },
    {
      title: "Prince",
      imageUrl: prince,
      projectUrl: "/Prince",
    },
    {
      title: "Michael Jordan",
      imageUrl: no_caption_007,
      projectUrl: "/MichaelJordan",
    },
    {
      title: "Kawhi Leonard",
      imageUrl: Kawhi_Leonard_2020,
      projectUrl: "/KawhiLeonard",
    },
    {
      title: "J Dilla",
      imageUrl: jDilla,
      projectUrl: "/JDilla",
    },
    {
      title: "Audrey Hepburn",
      imageUrl: audreyHepburn,
      projectUrl: "/AudreyHepburn",
    },
    {
      title: "Muhammad Ali",
      imageUrl: muhammadAli,
      projectUrl: "/MuhammadAli",
    },
    {
      title: "Elizabeth Taylor",
      imageUrl: elizabethTaylor,
      projectUrl: "/ElizabethTaylor",
    },
    {
      title: "Elizabeth Taylor 2",
      imageUrl: elizabethTaylor2,
      projectUrl: "/ElizabethTaylor2",
    },
    {
      title: "Stephen Curry",
      imageUrl: Steph_Curry_the_Three_Point_King,
      projectUrl: "/StephenCurry",
    },
    {
      title: "Washing Hands",
      imageUrl: washingHands,
      projectUrl: "/WashingHands",
    },
    {
      title: "BOB MARLEY",
      imageUrl: markey,
      projectUrl: "/BobMarley",
    },
    {
      title: "Left Eye Original",
      imageUrl: leftEyeOriginal,
      projectUrl: "/LeftEyeOriginal",
    },
    {
      title: "Kevin Durant",
      imageUrl: no_caption_014,
      projectUrl: "/KevinDurant",
    },
  ];




  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const intervalRef = useRef(null);
  const h2Ref = useRef(null); // Ref for h2 element


  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    if (!isMobile) {
      const section = document.querySelector(".section-project-description");

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            section.classList.add("fade-out");
          } else {
            section.classList.remove("fade-out");
          }
        });
      });

      observer.observe(section);

      return () => observer.disconnect();
    }
  }, [isMobile]);

  return (
    <>
      <div className="Content">
        {isMobile ? (
          <>
            <section className="section-project-description">
              <h2
                className="project-title2"
                ref={h2Ref}
                data-value="ORIGINAL PAINTINGS"
              >
                ORIGINAL PAINTINGS
              </h2>
            </section>
            <div className="upper-section">
              <ProjectSampleMobile items={items} />
            </div>
          </>
        ) : (
          <>
            <section className="section-project-description">
              <h2
                className="project-title2"
                ref={h2Ref}
                data-value="ORIGINAL PAINTINGS"
              >
                ORIGINAL PAINTINGS
              </h2>
            </section>
            <div className="upper-section">
              <ProjectSample items={items} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Paintings;

import viewla from "../../image/originals/lockdown/01viewla.webp";
import googlethis from "../../image/originals/lockdown/02googlethis.webp";
import romeoJuliet from "../../image/originals/lockdown/03romeojuliet.webp";
import washingHands from "../../image/originals/lockdown/04washinghands.webp";

import Collection from "./Collection2";

const LockdownCollection = () => {
  const title = "LOCKDOWNS MADE ME PAINT THIS";

  const items = [
    {
      title: "VIEW OF LA (SELF-PORTRAIT), 2020",
      imageUrl: viewla,
      projectUrl: "/ViewOfLa",
    },
    {
      title: "ROMEO + JULIET, 2020",
      imageUrl: romeoJuliet,
      projectUrl: "/RomeoAndJuliet",
    },
    {
      title: "GOOGLE THIS, 2020",
      imageUrl: googlethis,
      projectUrl: "/GoogleThis",
    },
    {
      title: "WASHING HANDS (SELF- PORTRAIT), 2020",
      imageUrl: washingHands,
      projectUrl: "/WashingHands",
    },
  ];

  return <Collection items={items} title={title} backgroundImage={washingHands} />;
};

export default LockdownCollection;

import radical from "../../image/originals/radical/free.webp"

import Collection from "./Collection2";

const LockdownCollection = () => {
  const title = "FREE RADICALS";

  const items = [
    {
      title: "Reena Rose",
      imageUrl: radical,
      projectUrl: "/ReenaRose",
    },
  ];

  return <Collection items={items} title={title} />;
};

export default LockdownCollection;

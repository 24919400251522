import image009 from "../../../image/originals/gold/009.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: 0,
      name: "Queen Holding Toddler",
      image: image009,
      price: "$5100.00",
      thumbnails: [image009],
      description:
        "Acrylic and oil on canvas of a motherly queen holding her child.",
      projectUrl: "/QueenHoldingToddler",
      sizes: ["40 X 50"],
      available: "sold",
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import shaq from "../../../image/originals/nba/shaq.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "49_base",
      name: "Shaq",
      image: shaq, // Main image without .webp
      price: "$5500.00",
      thumbnails: [shaq], // Other images (if available)
      description: "36” x 48” ACRYLIC ON CANVAS",
      projectUrl: "/Shaq",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

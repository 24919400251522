import Neymar1 from "../../../image/originals/futbol/Neymar1.webp"; import Neymar2 from "../../../image/originals/futbol/Neymar2.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "21_base",
      name: "NEYMAR",
      image: Neymar1, // Main image without .webp
      price: "$6000.00",
      thumbnails: [Neymar1, Neymar2], // Other images (if available)
      description: "36 IN X 36 IN ACRYLIC, OIL PASTEL ON CANVAS",
      projectUrl: "/Neymar",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

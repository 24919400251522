import bobmarley from "../../../image/originals/hollywoodicons/bobmarley.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "65_base",
      name: "BOB MARLEY",
      image: bobmarley, // Main image
      price: "empty for now",
      thumbnails: [bobmarley], // Other images (if available)
      description: "6ft x 7ft Acrylic and oil pastel on linen Personal quote on reverse side of canvas",
      projectUrl: "/BOBMARLEY",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import HarryKane from "../../image/originals/futbol/Harrykane.webp";
import Mbappe from "../../image/originals/futbol/Mbappe.webp";
import Messi1 from "../../image/originals/futbol/Messi1.webp";
import MoSalah from "../../image/originals/futbol/MoSalah.webp";
import Neymar1 from "../../image/originals/futbol/Neymar1.webp";
import HarryKane2 from "../../image/originals/futbol/HaryKane2.webp";
import Mbappe2 from "../../image/originals/futbol/Mbppa2.webp";
import Messi2 from "../../image/originals/futbol/Messi2.webp";
import MoSalah2 from "../../image/originals/futbol/MoSalah2.webp";
import Neymar2 from "../../image/originals/futbol/Neymar2.webp";

import Collection from "./Collection2";

const FutbolCollection = () => {
  const title = "FUTBOL ICONS";

  const items = [
    {
      title: "NEYMAR",
      imageUrl: Neymar1,
      projectUrl: "/Neymar",
    },
    {
      title: "LIONEL MESSI",
      imageUrl: Messi1,
      projectUrl: "/LionelMessi",
    },
    {
      title: "HARRY KANE",
      imageUrl: HarryKane,
      projectUrl: "/HarryKane",
    },
    {
      title: "MO SALAH",
      imageUrl: MoSalah,
      projectUrl: "/MohamedSalah",
    },
    {
      title: "KYLIAN MBAPPE",
      imageUrl: Mbappe,
      projectUrl: "/KylianMbappe",
    },
  ];

  return (
    <Collection items={items} title={title} backgroundImage={Mbappe} />
  );
};

export default FutbolCollection;

import macmiller from "../../../image/originals/hollywoodicons/macmiller.webp"; import macmiller2 from "../../../image/originals/hollywoodicons/macmiller2.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: 0,
      name: "Mac Miller",
      image: macmiller, // Main image
      price: "empty for now",
      thumbnails: [macmiller, macmiller2], // Other images (if available)
      description:
        "18 by 24”Acrylic and Glitter on canvas Glows in the dark(+ back of painting photo)",
      projectUrl: "/MacMiller",
      sizes: ["blank"],
      available: "sold",
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

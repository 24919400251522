import L from "../../../image/originals/gold/L.webp";
import L1 from "../../../image/originals/gold/L1.webp";

import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "32_base",
      name: "L",
      image: L,
      price: "$3800.00",
      thumbnails: [L, L1],
      description:
        'Acrylic on canvas  Size: 24” x 73”  Personalized Quote Painted on the back of the piece.  (Unstretched)',
      projectUrl: "",
      sizes: [""],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

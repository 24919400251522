import image017 from "../../../image/originals/gold/017.webp";
import image018 from "../../../image/originals/gold/018.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: 0,
      name: "Father Lounging with Child",
      image: image017,
      price: "$4800.00",
      thumbnails: [image017, image018], // Front and Back images
      description:
        "Acrylic and oil on canvas of a father relaxing with his child.",
      projectUrl: "/FatherLoungingWithChild",
      sizes: ["48 X 36"],
      available: "sold",
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

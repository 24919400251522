import image026 from "../../../image/originals/gold/026.webp";
import image027 from '../../../image/originals/gold/027.webp'
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: 0,
      name: "Familyhood",
      image: image026,
      price: "$5400.00",
      thumbnails: [image026, image027],
      description:
        "Acrylic and oil on canvas celebrating the beauty of family.",
      projectUrl: "/Familyhood",
      sizes: ["40 X 50"],
      available: "sold",
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

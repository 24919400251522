import iam from "../../../image/stickers/iam1.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "81_base",
      name: "“I AM ART” sticker",
      image: [iam],
      price: "$4.99",
      thumbnails: [iam], // Front and Back images (if available)
      description: '3" x 3"',
      projectUrl: "/Iam",
      sizes: ["50 X 40"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

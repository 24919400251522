import frida from "../../../image/originals/hollywoodicons/frida.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "71_base",
      name: "Frida",
      image: frida, // Main image
      price: "empty for now",
      thumbnails: [frida], // Other images (if available)
      description: "24” x 41” in. Acrylic on Bamboo",
      projectUrl: "/Frida",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

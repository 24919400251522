import frida from "../../image/originals/hollywoodicons/frida.webp";
import bobMarleyDreads from "../../image/originals/hollywoodicons/marleydreads.webp"; // Corrected path
import basquiatDetails from "../../image/originals/hollywoodicons/basq.webp"; // Corrected path
import bobMarleyJammin from "../../image/originals/hollywoodicons/marleyjammin.webp";
import freshPrince from "../../image/originals/hollywoodicons/freshprince.webp";
import princessLeiaOriginal from "../../image/originals/hollywoodicons/princessleia.webp";
import whitneyHoustonOriginal from "../../image/originals/hollywoodicons/whitney.webp";
import davidBowie from "../../image/originals/hollywoodicons/bowie.webp";
import princessDianaOriginal from "../../image/originals/hollywoodicons/diana.webp"; // Corrected path
import elizabethTaylor from "../../image/originals/hollywoodicons/liztaylor.webp";
import jimMorrison from "../../image/originals/hollywoodicons/jimmorrison.webp";
import bruceLee from "../../image/originals/hollywoodicons/brucelee.webp";
import michaelJacksonOriginal from "../../image/originals/hollywoodicons/michaeljackson.webp";
import jDilla from "../../image/originals/hollywoodicons/jaydee.webp";
import sophiaLoren from "../../image/originals/hollywoodicons/SophiaLoren.webp";
import audreyHepburn from "../../image/originals/hollywoodicons/audreyhepburn.webp"; // Corrected path
import virgilAbloh from "../../image/originals/hollywoodicons/VirgilAbloh.webp";
import leftEyeOriginal from "../../image/originals/hollywoodicons/lisalefteye.webp";
import prince from "../../image/originals/hollywoodicons/Prince.webp";
import tigerWoods from "../../image/originals/hollywoodicons/TigerWoods.webp";
import elonMusk from "../../image/originals/hollywoodicons/musk.webp";
import elizabethTaylor2 from "../../image/originals/hollywoodicons/liztaylor2.webp";
import princessDiana from "../../image/originals/hollywoodicons/princessdiana2.webp";
import macMiller from "../../image/originals/hollywoodicons/macmiller.webp";
import muhammadAli from "../../image/originals/hollywoodicons/MuhammadAli.webp";
import markey from '../../image/originals/hollywoodicons/bobmarley.webp'

import Collection from "./Collection2";
const LockdownCollection = () => {
  const title = "HOLLYWOOD ICONS";

  // const items = [
  //   { title: "Frida", imageUrl: frida, projectUrl: "" },
  //   { title: "Bob Marley Dreads", imageUrl: bobMarleyDreads, projectUrl: "" },
  //   { title: "Basquiat Details", imageUrl: basquiatDetails, projectUrl: "" },
  //   { title: "Bob Marley Jammin", imageUrl: bobMarleyJammin, projectUrl: "" },
  //   { title: "Fresh Prince", imageUrl: freshPrince, projectUrl: "" },
  //   {
  //     title: "Princess Leia Original",
  //     imageUrl: princessLeiaOriginal,
  //     projectUrl: "",
  //   },
  //   {
  //     title: "Whitney Houston Original",
  //     imageUrl: whitneyHoustonOriginal,
  //     projectUrl: "",
  //   },
  //   { title: "David Bowie", imageUrl: davidBowie, projectUrl: "" },
  //   {
  //     title: "Princess Diana Original",
  //     imageUrl: princessDianaOriginal,
  //     projectUrl: "",
  //   },
  //   { title: "Elizabeth Taylor", imageUrl: elizabethTaylor, projectUrl: "" },
  //   { title: "Jim Morrison", imageUrl: jimMorrison, projectUrl: "" },
  //   { title: "Bruce Lee", imageUrl: bruceLee, projectUrl: "" },
  //   {
  //     title: "Michael Jackson Original",
  //     imageUrl: michaelJacksonOriginal,
  //     projectUrl: "",
  //   },
  //   { title: "BOB MARLEY", imageUrl: markey, projectUrl: "" },
  //   { title: "J Dilla", imageUrl: jDilla, projectUrl: "" },
  //   { title: "Sophia Loren", imageUrl: sophiaLoren, projectUrl: "" },
  //   { title: "Audrey Hepburn", imageUrl: audreyHepburn, projectUrl: "" },
  //   { title: "Virgil Abloh", imageUrl: virgilAbloh, projectUrl: "" },
  //   { title: "Left Eye Original", imageUrl: leftEyeOriginal, projectUrl: "" },
  //   { title: "Prince", imageUrl: prince, projectUrl: "" },
  //   { title: "Tiger Woods", imageUrl: tigerWoods, projectUrl: "" },
  //   { title: "Elon Musk", imageUrl: elonMusk, projectUrl: "" },
  //   { title: "Elizabeth Taylor 2", imageUrl: elizabethTaylor2, projectUrl: "" },
  //   { title: "Princess Diana", imageUrl: princessDiana, projectUrl: "" },
  //   { title: "Mac Miller", imageUrl: macMiller, projectUrl: "" },
  //   { title: "Muhammad Ali", imageUrl: muhammadAli, projectUrl: "" },
  // ];

  const items = [
    { title: "Frida", imageUrl: frida, projectUrl: "/Frida" },
    {
      title: "Bob Marley Dreads",
      imageUrl: bobMarleyDreads,
      projectUrl: "/BobMarleyDreads",
    },
    {
      title: "Basquiat Details",
      imageUrl: basquiatDetails,
      projectUrl: "/BasquiatDetails",
    },
    {
      title: "Bob Marley Jammin",
      imageUrl: bobMarleyJammin,
      projectUrl: "/BobMarleyJammin",
    },
    {
      title: "Fresh Prince",
      imageUrl: freshPrince,
      projectUrl: "/FreshPrince",
    },
    {
      title: "Princess Leia Original",
      imageUrl: princessLeiaOriginal,
      projectUrl: "/PrincessLeiaOriginal",
    },
    {
      title: "Whitney Houston Original",
      imageUrl: whitneyHoustonOriginal,
      projectUrl: "/WhitneyHoustonOriginal",
    },
    { title: "David Bowie", imageUrl: davidBowie, projectUrl: "/DavidBowie" },
    {
      title: "Princess Diana Original",
      imageUrl: princessDianaOriginal,
      projectUrl: "/PrincessDianaOriginal",
    },
    {
      title: "Elizabeth Taylor",
      imageUrl: elizabethTaylor,
      projectUrl: "/ElizabethTaylor",
    },
    {
      title: "Jim Morrison",
      imageUrl: jimMorrison,
      projectUrl: "/JimMorrison",
    },
    { title: "Bruce Lee", imageUrl: bruceLee, projectUrl: "/BruceLee" },
    {
      title: "Michael Jackson Original",
      imageUrl: michaelJacksonOriginal,
      projectUrl: "/MichaelJacksonOriginal",
    },
    { title: "BOB MARLEY", imageUrl: markey, projectUrl: "/BobMarley" },
    { title: "J Dilla", imageUrl: jDilla, projectUrl: "/JDilla" },
    {
      title: "Sophia Loren",
      imageUrl: sophiaLoren,
      projectUrl: "/SophiaLoren",
    },
    {
      title: "Audrey Hepburn",
      imageUrl: audreyHepburn,
      projectUrl: "/AudreyHepburn",
    },
    {
      title: "Virgil Abloh",
      imageUrl: virgilAbloh,
      projectUrl: "/VirgilAbloh",
    },
    {
      title: "Left Eye Original",
      imageUrl: leftEyeOriginal,
      projectUrl: "/LeftEyeOriginal",
    },
    { title: "Prince", imageUrl: prince, projectUrl: "/Prince" },
    { title: "Tiger Woods", imageUrl: tigerWoods, projectUrl: "/TigerWoods" },
    {
      title: "Elon Musk",
      imageUrl: elonMusk,
      available: "out of stock",
      projectUrl: "/ElonMusk",
    },
    {
      title: "Elizabeth Taylor 2",
      imageUrl: elizabethTaylor2,
      available: "out of stock",
      projectUrl: "/ElizabethTaylor2",
    },
    {
      title: "Princess Diana",
      imageUrl: princessDiana,
      available: "out of stock",
      projectUrl: "/PrincessDiana",
    },
    {
      title: "Mac Miller",
      imageUrl: macMiller,
      available: "out of stock",
      projectUrl: "/MacMiller",
    },
    {
      title: "Muhammad Ali",
      imageUrl: muhammadAli,
      available: "out of stock",
      projectUrl: "/MuhammadAli",
    },
  ];


  return (
    <Collection items={items} title={title} backgroundImage={virgilAbloh} />
  );
};

export default LockdownCollection;

import image010 from "../../../image/originals/gold/010.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "34_base",
      name: "Young Love",
      image: image010,
      price: "$3000.00",
      thumbnails: [image010],
      description: "Acrylic and oil on canvas showing young and tender love.",
      projectUrl: "/YoungLove",
      sizes: ["48 X 36"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

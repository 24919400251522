import durant from "../../../image/originals/nba/durant.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: 0,
      name: "Kevin Durant",
      image: durant, // Main image without .webp
      price: "empty for now",
      thumbnails: [durant], // Other images (if available)
      description: "30” X 48” Acrylic & Glitter on canvas",
      projectUrl: "/KevinDurant",
      sizes: ["blank"],
      available: "sold",
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

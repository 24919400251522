import twinflame from '../../../image/originals/quantum/twinFlame.webp'
import twinflame2 from "../../../image/originals/quantum/twinflame2.webp";




import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "1_base",
      name: "Twin Flame Original",
      image: [twinflame],
      price: "$15000.00",
      thumbnails: [twinflame, twinflame2], // Front and Back images (if available)
      description:
        "Acrylic and oil pastel depicting the connection between twin flames.",
      projectUrl: "/TwinFlameOriginal",
      sizes: ["48 X 48"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import one from "../../../image/originals/abstracts/1.webp";
import one_back from "../../../image/originals/abstracts/1_1.webp";import ItemWide from "../ItemWide";

const Shop = () => {
  const items = [
    {
      id: "41_base",
      name: "Fool Them Not Me",
      image: one, // Main image
      price: "$5000.00",
      thumbnails: [one, one_back], // Other images (if available)
      description:
        "Acrylic on canvas  Size: 24” x 73”  Personalized Quote Painted on the back of the piece.  (Unstretched)",
      projectUrl: "/FoolThemNotMe",
      sizes: [""],
    },
  ];

  return <ItemWide items={items} />;
};

export default Shop;

import five from "../../../image/originals/abstracts/5.webp";
import five_back from "../../../image/originals/abstracts/5_1.webp";import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "39_base",
      name: "It Came In A Dream",
      image: five, // Main image
      price: "$1000.00",
      thumbnails: [five, five_back], // Other images (if available)
      description:
        "19 x 33 in.  Acrylic, oil pastel on canvas linen A personalized quote painted on the back of the piece (unstretched)",
      projectUrl: "/ItCameInADream",
      sizes: ["36 X 48"],
      available: "sold",
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

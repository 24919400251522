import kawahi from "../../../image/originals/nba/kawahi.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "45_base",
      name: "Kawhi Leonard",
      image: kawahi, // Main image without .webp
      price: "$6000.00",
      thumbnails: [kawahi], // Other images (if available)
      description: "30” X 48” ACRYLIC & TEXTURIZED PEARL ON CANVAS",
      projectUrl: "/KawhiLeonard",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

// CartContext.js
import React, { createContext, useState, useEffect } from 'react';

// Create a context
export const CartContext = createContext();

// Create a provider component
export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState([]);

    // Load cart from local storage on mount
    useEffect(() => {
        const savedCart = localStorage.getItem('cart');
        if (savedCart) {
            setCart(JSON.parse(savedCart));
        }
    }, []); 

    // Save cart to local storage on cart change
    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    const addToCart = (item, selectedSize) => {
        setCart((prevCart) => {
            const existingItem = prevCart.find(
                (cartItem) => cartItem.id === item.id && cartItem.size === selectedSize
            );

            let updatedCart;
            if (existingItem) {
                updatedCart = prevCart.map((cartItem) =>
                    cartItem.id === item.id && cartItem.size === selectedSize
                        ? { ...cartItem, quantity: cartItem.quantity + 1 }
                        : cartItem
                );
            } else {
                updatedCart = [
                    ...prevCart,
                    { ...item, quantity: 1, size: selectedSize },
                ];
            }
            
            return updatedCart;
        });
        // console.log(cart)
    };
    console.log(cart)

    // const removeFromCart = (id, size) => {
    //     setCart((prevCart) => {
    //         const existingItem = prevCart.find(
    //             (cartItem) => cartItem.id === item.id && cartItem.size === selectedSize
    //         );

    //         // let updatedCart;
    //         const updatedCart = prevCart
    //             .map((item) =>
    //                 item.id === id && item.size === size
    //                     ? { ...item, quantity: Math.max(item.quantity - 1, 0) }
    //                     : item
    //             )
    //             .filter((item) => item.quantity > 0);

    //         return updatedCart;
    //     });
    // };
    const removeFromCart = (id, size) => {
        setCart((prevCart) => {
            // Check if there is a matching item in the cart
            const existingItem = prevCart.find(
                (cartItem) => cartItem.id === id && cartItem.size === size
            );

            // Warn if no matching item is found
            if (!existingItem) {
                console.warn(`No matching item found in cart for id: ${id}, size: ${size}`);
                return prevCart; // Return the cart unchanged if no match
            }

            // Update the cart if a matching item is found
            const updatedCart = prevCart
                .map((item) =>
                    item.id === id && item.size === size
                        ? { ...item, quantity: Math.max(item.quantity - 1, 0) }
                        : item
                )
                .filter((item) => item.quantity > 0);

            console.log(updatedCart)
            return updatedCart;
        });
    };


    return (
        <CartContext.Provider value={{ cart, addToCart, removeFromCart }}>
            {children}
        </CartContext.Provider>
    );
};

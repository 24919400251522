import purse1 from '../../image/purses/purses1.webp'
import purse2 from '../../image/purses/purses2.webp'
import purse3 from '../../image/purses/purses3.webp'
import purse4 from '../../image/purses/purses4.webp'
import purse5 from '../../image/purses/purses5.webp'
import purse6 from '../../image/purses/purses6.webp'


import Items from "./ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "82_base",
      name: "All Star Purse",
      image: purse1,
      price: "$750.00",
      thumbnails: [purse1, purse2, purse3, purse4, purse5, purse6],
      description:
        "ALL STAR Basketball Purse  With inside and outside pockets. Inside small pocket in the inside and larger pocket for carrying cell phone and such. (This is a perfect bag for content creators as they set their camera phone and able to capture the life of the party while still being engaging.)  Basketball purse is custom designed for the Content Creator/ influencer/ Basketball lover who doesn’t mind being the center of attention with custom paint drips by RT. The purse is supported by a metal chain that is adjustable. The purse is painted with Acrylic paint and varnished to protect the art.  *40% of the proceeds will go to a 501(c) (3) charity, Arte Youth Program for Filipino and minority youth in creative S.T.E.A.M education.",
      projectUrl: "",
      // sizes: ["Small", "Medium", "Large"],
    },
  ];

  return <Items items={items} />;
};

export default Shop;
import lisalefteye from "../../../image/originals/hollywoodicons/lisalefteye.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "55_base",
      name: "Left Eye Original",
      image: lisalefteye, // Main image
      price: "empty for now",
      thumbnails: [lisalefteye], // Other images (if available)
      description: '30" x 40"ACRYLIC ON CANVAS',
      projectUrl: "/LeftEyeOriginal",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

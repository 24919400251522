import image005 from "../../../image/originals/gold/005.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: 0,
      name: "Queen and Child",
      image: image005,
      price: "$4700.00",
      thumbnails: [image005],
      description:
        "Acrylic and oil on canvas showing the majesty of motherhood.",
      projectUrl: "/QueenAndChild",
      sizes: ["40 X 50"],
      available: "sold",
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import michaeljackson from "../../../image/originals/hollywoodicons/michaeljackson.webp"; import michaeljackson2 from "../../../image/originals/hollywoodicons/michaeljackson2.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "59_base",
      name: "Michael Jackson Original",
      image: michaeljackson, // Main image
      price: "empty for now",
      thumbnails: [michaeljackson, michaeljackson2], // Other images (if available)
      description:
        "36 by 36 “ Acrylic and Glitter on canvas (+ back of painting photo)",
      projectUrl: "/MichaelJacksonOriginal",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

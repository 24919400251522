import React from "react";
// import "./NavHamburger.css";

const NavHamburger = ({ isOpen, toggleHamburger }) => {
  return (
    <div className="hamburger-container">
      <div
        className={`hamburger ${isOpen ? "open" : ""}`}
        onClick={toggleHamburger}
        data-testid="toggles"
      >
        <div className="burger burger1" />
        <div className="burger burger2" />
        <div className="burger burger3" />
      </div>
      {isOpen && (
        <div className="close-button" onClick={toggleHamburger}>
          &#10305; {/* HTML entity for 'x' */}
        </div>
      )}
    </div>
  );
};

export default NavHamburger;

import bowie from "../../../image/originals/hollywoodicons/bowie.webp"; import bowie2 from "../../../image/originals/hollywoodicons/bowie2.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "60_base",
      name: "David Bowie",
      image: bowie, // Main image
      price: "empty for now",
      thumbnails: [bowie, bowie2], // Other images (if available)
      description: '30" x 30”Acrylic and Glitter on Linen,Glows',
      projectUrl: "/DavidBowie",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

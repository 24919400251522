import twinFlame from "../../../image/originals/quantum/twinFlame.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "96_base",
      name: "Twin Flame Print",
      image: twinFlame, // Main image without .webp
      price: "empty for now",
      thumbnails: [twinFlame], // Other images (if available)
      description: "20 x 20” Museum Rag Paper.",
      projectUrl: "/TwinFlamePrint",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import anthony from "../../../image/originals/nba/anthony.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "43_base",
      name: "Anthony Davis",
      image: anthony, // Main image without .webp
      price: "$6000.00",
      thumbnails: [anthony], // Other images (if available)
      description: "30” X 48” ACRYLIC & GLOW IN THE DARK ON CANVAS",
      projectUrl: "/AnthonyDavis",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

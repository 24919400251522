import cosmonaut from "../../../image/originals/quantum/creativecosmonaut.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "17_base",
      name: "Creative Cosmonaut",
      image: [cosmonaut], // Correct image import
      price: "$4800.00",
      thumbnails: [cosmonaut], // Front and back images if available
      description:
        "A cosmonaut floating through a world of dreams, captured in oil and pastel.",
      projectUrl: "/CreativeCosmonaut",
      sizes: ["60 X 48"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

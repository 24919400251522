import image008 from "../../../image/originals/gold/008.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "37_base",
      name: "Queen and Her Pets",
      image: image008,
      price: "$3000.00",
      thumbnails: [image008],
      description: "Acrylic and oil on canvas of a queen with her loyal pets.",
      projectUrl: "/QueenAndHerPets",
      sizes: ["48 X 36"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

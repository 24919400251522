import musk from "../../../image/originals/hollywoodicons/musk.webp"; import musk2 from "../../../image/originals/hollywoodicons/musk2.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: 0,
      name: "Elon Musk",
      image: musk, // Main image
      price: "empty for now",
      thumbnails: [musk, musk2], // Other images (if available)
      description:
        "24 x 30 in on Artefax panel Acrylic, spray, oil pastel (Glow in the dark)",
      projectUrl: "/ElonMusk",
      sizes: ["blank"],
      available: "sold",
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import paradox from "../../../image/originals/quantum/paradox.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "99_base",
      name: "Paradox Print",
      image: paradox, // Main image without .webp
      price: "empty for now",
      thumbnails: [paradox], // Other images (if available)
      description: "20 x 20” Museum Rag Paper.",
      projectUrl: "/ParadoxPrint",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

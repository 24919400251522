import whitney from "../../../image/originals/hollywoodicons/whitney.webp"; import whitney2 from "../../../image/originals/hollywoodicons/whitney2.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "61_base",
      name: "Whitney Houston Original",
      image: whitney, // Main image
      price: "empty for now",
      thumbnails: [whitney, whitney2], // Other images (if available)
      description: "36 by 36”Acrylic on canvas.(+ back of painting photo)",
      projectUrl: "/WhitneyHoustonOriginal",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;



import kyrie from "../../../image/originals/nba/kyrie.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "46_base",
      name: "Kyrie Irving",
      image: kyrie, // Main image without .webp
      price: "$5550.00",
      thumbnails: [kyrie], // Other images (if available)
      description: "36” X 36” ACRYLIC & GLITTER ON CANVAS",
      projectUrl: "/KyrieIrving",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

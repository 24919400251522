import brucelee from "../../../image/originals/hollywoodicons/brucelee.webp"; import brucelee2 from "../../../image/originals/hollywoodicons/brucelee2.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "58_base",
      name: "Bruce Lee",
      image: brucelee, // Main image
      price: "empty for now",
      thumbnails: [brucelee, brucelee2], // Other images (if available)
      description: '36" by 48 “ Acrylic and pastel on canvas Glows in the dark(+ back of painting photo)',
      projectUrl: "/BruceLee",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

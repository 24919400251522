import universe from "../../image/originals/quantum/spaceuniverse.webp";
import leaps from "../../image/originals/quantum/LEAPS.webp";
import risks from "../../image/originals/quantum/RISKS.webp";
import creativecosmonaut from "../../image/originals/quantum/creativecosmonaut.webp";
import grounded from "../../image/originals/quantum/GROUNDED.webp";
import paradox from "../../image/originals/quantum/paradox.webp";
import selene_princess from "../../image/originals/quantum/Selene_Princess.webp";
import eighteen from "../../image/originals/quantum/infiniteflames.webp";
import floating from "../../image/originals/quantum/floating.webp";
import currentevents from "../../image/originals/quantum/currentevents.webp";
import thanos from "../../image/originals/quantum/thanos.webp";
import foreverentangled from "../../image/originals/quantum/foreverentangled.webp";
import followyourgut from "../../image/originals/quantum/followyourgut.webp";
import spacewars from "../../image/originals/quantum/spacewars.webp";
import cosmowarrior from "../../image/originals/quantum/cosmowarrior.webp";
import escaping from "../../image/originals/quantum/escaping.webp";
import keytoyourheart from "../../image/originals/quantum/keytoyourheart.webp";
import myopicmen from "../../image/originals/quantum/myopicmen.webp";
import twinflame from "../../image/originals/quantum/twinFlame.webp";

import Collection from "./Collection2";

const QuantumCollection = () => {
  const title = "Quantum Entanglement";

  // const items = [
  //   { imageUrl: universe, title: "The Universe Original" },
  //   { imageUrl: leaps, title: "Leaps Original" },
  //   { imageUrl: risks, title: "Risks Original", projectUrl: "/Risk" },
  //   {
  //     imageUrl: creativecosmonaut,
  //     title: "Creative Cosmonaut",
  //   },
  //   { imageUrl: grounded, title: "Grounded For You Original" },
  //   {
  //     imageUrl:
  //       paradox,
  //     title: "Paradox Original",
  //   },
  //   { imageUrl: selene_princess, title: "Selene Princess Original" },
  //   {
  //     imageUrl: eighteen,
  //     title: "Infinite Flames",
  //   },
  //   {
  //     title: "Floating in Space",
  //     imageUrl: floating,
  //     projectUrl: "/Floating",
  //   },
  //   { imageUrl: currentevents, title: "Current Events 2021" },
  //   {
  //     imageUrl: twenty,
  //     title: "Thanos in Space",
  //   },
  //   {
  //     imageUrl: twentyTwo,
  //     title: "Forever Entangled",
  //   },
  //   {
  //     imageUrl: twentyThree,
  //     title: "Follow Your Gut",
  //   },
  //   {
  //     imageUrl: twentySix,
  //     title: "Space Wars",
  //   },
  //   {
  //     imageUrl: twentyEight,
  //     title: "Cosmo Warrior",
  //     projectUrl: "./CosmoWarrior"
  //   },
  //   {
  //     imageUrl: thirty,
  //     title: "Escaping",
  //   },
  //   {
  //     imageUrl: thirtyOne,
  //     title: "Key to your Heart",
  //   },
  //   {
  //     imageUrl: thirtyThree,
  //     title: "Myopic Men",
  //   },
  //   {
  //     title: "Twin Flame Original",
  //     imageUrl: twinflame,
  //   }
  // ];
  // const items = [
  //   { imageUrl: universe, title: "The Universe Original" },
  //   { imageUrl: leaps, title: "Leaps Original" },
  //   { imageUrl: risks, title: "Risks Original", projectUrl: "/Risk" },
  //   { imageUrl: creativecosmonaut, title: "Creative Cosmonaut" },
  //   { imageUrl: grounded, title: "Grounded For You Original" },
  //   { imageUrl: paradox, title: "Paradox Original" },
  //   { imageUrl: selene_princess, title: "Selene Princess Original" },
  //   { imageUrl: eighteen, title: "Infinite Flames" },
  //   { title: "Floating in Space", imageUrl: floating, projectUrl: "/Floating" },
  //   { imageUrl: currentevents, title: "Current Events 2021" },
  //   { imageUrl: thanos, title: "Thanos in Space" },
  //   { imageUrl: foreverentangled, title: "Forever Entangled" },
  //   { imageUrl: followyourgut, title: "Follow Your Gut" },
  //   { imageUrl: spacewars, title: "Space Wars" },
  //   {
  //     imageUrl: cosmowarrior,
  //     title: "Cosmo Warrior",
  //     projectUrl: "./CosmoWarrior",
  //   },
  //   { imageUrl: escaping, title: "Escaping" },
  //   { imageUrl: keytoyourheart, title: "Key to your Heart" },
  //   { imageUrl: myopicmen, title: "Myopic Men" },
  //   { imageUrl: twinflame, title: "Twin Flame Original" },
  // ];

  const items = [
    {
      imageUrl: universe,
      title: "The Universe Original",
      projectUrl: "/UniverseOriginal",
    },
    { imageUrl: leaps, title: "Leaps Original", projectUrl: "/LeapsOriginal" },
    { imageUrl: risks, title: "Risks Original", projectUrl: "/RisksOriginal" },
    {
      imageUrl: creativecosmonaut,
      title: "Creative Cosmonaut",
      projectUrl: "/CreativeCosmonaut",
    },
    {
      imageUrl: grounded,
      title: "Grounded For You Original",
      projectUrl: "/GroundedForYou",
    },
    {
      imageUrl: paradox,
      title: "Paradox Original",
      projectUrl: "/ParadoxOriginal",
    },
    {
      imageUrl: selene_princess,
      title: "Selene Princess Original",
      projectUrl: "/SelenePrincess",
    },
    {
      imageUrl: eighteen,
      title: "Infinite Flames",
      projectUrl: "/InfiniteFlames",
    },
    { title: "Floating in Space", imageUrl: floating, projectUrl: "/FloatingInSpace" },
    {
      imageUrl: currentevents,
      title: "Current Events 2021",
      projectUrl: "/CurrentEvents",
    },
    { imageUrl: thanos, title: "Thanos in Space", projectUrl: "./Thanos" },
    {
      imageUrl: foreverentangled,
      title: "Forever Entangled",
      projectUrl: "/ForeverEntangled",
    },
    {
      imageUrl: followyourgut,
      title: "Follow Your Gut",
      projectUrl: "/FollowYourGut",
    },
    { imageUrl: spacewars, title: "Space Wars", projectUrl: "/SpaceWars" },
    {
      imageUrl: cosmowarrior,
      title: "Cosmo Warrior",
      projectUrl: "/CosmoWarrior",
    },
    { imageUrl: escaping, title: "Escaping", projectUrl: "./Escaping" },
    {
      imageUrl: keytoyourheart,
      title: "Key to your Heart",
      projectUrl: "/KeyToYourHeart",
    },
    { imageUrl: myopicmen, title: "Myopic Men", projectUrl: "/MyopicMen" },
    {
      imageUrl: twinflame,
      title: "Twin Flame Original",
      projectUrl: "/TwinFlameOriginal",
    },
  ];

  return <Collection items={items} title={title} backgroundImage={leaps} />;
};

export default QuantumCollection;

// {
//   imageUrl: I_saw_the_Moon,
//   title: 'Follow your gut. (glow in the dark/ black light version)"I saw the Moon."',
// },
// {
//   image: follow_your_gut_oil_pastel_acrylic_on_canvas,
//   name: "Follow your gut. Oil pastel, acrylic on canvas",
// },
// {
//   imageUrl: in_the_studio_creating_with_astronauts_2019,
//   title: "In the studio creating with Astronauts 2019",
// },
// {
//   imageUrl: reena_at_cambridge_university_showing_her_trilogy_series,
//   title: "Reena at Cambridge University showing her trilogy series.",
// },
// {
//   imageUrl:
//     reena_conducting_her_dark_painting_project_at_cambridge_university,
//   title:
//     'Reena conducting her "Dark Painting" project at Cambridge University',
// },
// { imageUrl: oddict_pods_campaign_2021, title: "Oddict pods Campaign 2021" },
// {
//   imageUrl: oddict_pods_campaign_2021_alternate,
//   title: "Oddict pods Campaign 2021",
// },
// {
//   imageUrl:
//     with_dr_jancy_mcphee_founder_of_sciartsexchange_for_a_steam_event,
//   title:
//     "with Dr. Jancy McPhee/ Founder of SciArtsExchange for a STEAM event.",
// },

import twoPiece from "../../image/flirty-lazuli/twopiecedress.webp";
import top from "../../image/flirty-lazuli/lauzlitop.webp";
import skirt from "../../image/flirty-lazuli/lazuliskirt.webp";

import lazuliskirt1 from "../../image/flirty-lazuli/lazuliskirt1.webp";
import lazulitop1 from "../../image/flirty-lazuli/lazulitop1.webp";
import lazulitwopiece from "../../image/flirty-lazuli/twopiece1.webp";


import Collection from "./Collection";

const LazuliCollection = () => {

  const title = "FLIRTY LAZULI COLLECTION"

  const items = [
    {
      title: "Flirty Lazuli Two Piece Dress",
      imageUrl: lazulitwopiece,
      projectUrl: "/FlirtyLazuliTwoPiece",
    },
    {
      title: "Flirty Lazuli Skirt",
      imageUrl: lazuliskirt1,
      projectUrl: "/FlirtyLazuliSkirt",
    },
    {
      title: "Flirty Lazuli Tops",
      imageUrl: lazulitop1,
      projectUrl: "/FlirtyLazuliTop",
    },
  ];

  return (
    <Collection items={items} title={title} />
  );
};

export default LazuliCollection;

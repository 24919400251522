import kobeprint from "../../../image/originals/hollywoodicons/kobeprint.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "93_base",
      name: "The Rise of Kobe Art Print",
      image: kobeprint, // Main image without .webp
      price: "empty for now",
      thumbnails: [kobeprint], // Other images (if available)
      description: 'A limited edition Kobe Bryant print on museum quality rag paper 20" X 20" Hand signed and numbered by artist. Edition of 50.',
      projectUrl: "/TheRiseofKobeArtPrint",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

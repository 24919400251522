import LebronCleveland from "../../../image/originals/nba/LebronCleveland.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: 0,
      name: "LeBron Cleveland",
      image: LebronCleveland, // Main image without .webp
      price: "empty for now",
      thumbnails: [LebronCleveland], // Other images (if available)
      description: "36” X 48” ACRYLIC ON CANVAS",
      projectUrl: "/LeBronCleveland",
      sizes: ["blank"],
      available: "sold",
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import Harrykane from "../../../image/originals/futbol/Harrykane.webp"; import HaryKane2 from "../../../image/originals/futbol/HaryKane2.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "23_base",
      name: "HARRY KANE",
      image: Harrykane, // Main image without .webp
      price: "$4000.00",
      thumbnails: [Harrykane, HaryKane2], // Other images (if available)
      description: "35 IN X 50 IN ACRYLIC, OIL PASTEL ON CANVAS",
      projectUrl: "/HarryKane",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import pants1 from '../../image/selene-collection/selenepants.webp'
import pants2 from '../../image/selene-collection/selenepants1.webp'


import Items from "./Item";

const Shop = () => {
  const items = [
    {
      id: "106",
      name: "Selene Princess Workout Pants",
      image: pants1,
      price: "$98.00",
      thumbnails: [pants1, pants2],
      description:
        "82/18 poly spandex, black poly mesh one color print made in Los Angeles",
      projectUrl: "",
      sizes: ["Small", "Medium", "Large"],
    },
  ];

  return <Items items={items} />;
};

export default Shop;
import thirty from "../../../image/originals/quantum/escaping.webp";

import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "16_base",
      name: "Escaping",
      image: [thirty],
      price: "$30000.00",
      thumbnails: [thirty], // Front and Back images (if available)
      description: "Acrylic and oil on canvas depicting the act of escaping.",
      projectUrl: "/Escaping",
      sizes: ["48 X 36"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

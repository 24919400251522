import just from "../../../image/stickers/justa.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "80_base",
      name: "“Without RT” sticker",
      image: [just],
      price: "$4.99",
      thumbnails: [just], // Front and Back images (if available)
      description: '3" x 3"',
      projectUrl: "/Without",
      sizes: ["50 X 40"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import lebronlakers from "../../../image/originals/nba/lebronlakers.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "47_base",
      name: "LeBron Lakers",
      image: lebronlakers, // Main image without .webp
      price: "$6000.00",
      thumbnails: [lebronlakers], // Other images (if available)
      description: "48” x 36” ACRYLIC, DIAMOND DUST & OIL PASTEL ON CANVAS",
      projectUrl: "/LeBronLakers",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

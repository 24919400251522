import SophiaLoren from "../../../image/originals/hollywoodicons/SophiaLoren.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "51_base",
      name: "Sophia Loren",
      image: SophiaLoren, // Main image
      price: "$500.00",
      thumbnails: [SophiaLoren], // Other images (if available)
      description: '16" x 20" Acrylic on Canvas Quote behind the painting: "Mistakes are the price of an interesting life"',
      projectUrl: "/SophiaLoren",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

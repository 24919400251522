import paradox from '../../../image/originals/quantum/paradox.webp'
import paradox2 from "../../../image/originals/quantum/paradox_back.webp";

import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "3_base",
      name: "Paradox Original",
      image: [paradox],
      price: "$15000.00",
      thumbnails: [paradox, paradox2], // Front and Back images (if available)
      description:
        "Acrylic and oil pastel illustrating Einstein's EPR theory of quantum entanglement.",
      projectUrl: "/ParadoxOriginal",
      sizes: ["48 X 48"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

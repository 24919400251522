import harden from "../../../image/originals/nba/harden.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: 0,
      name: "James Harden",
      image: harden, // Main image without .webp
      price: "empty for now",
      thumbnails: [harden], // Other images (if available)
      description: "36” X 36” ACRYLIC ON CANVAS",
      projectUrl: "/JamesHarden",
      sizes: ["blank"],
      available: "sold",
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import basquiatprint from "../../../image/originals/hollywoodicons/basquiatprint.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "83_base",
      name: "Jean-Michel Basquiat Print",
      image: basquiatprint, // Main image without .webp
      price: "empty for now",
      thumbnails: [basquiatprint], // Other images (if available)
      description: 'This limited Jean-Michel Basquiat print from RT’s Icon series. Printed on museum quality rag paper 20” x 20” Hand signed and numbered by artist. Edition of 50.',
      projectUrl: "/Jean-MichelBasquiatPrint",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

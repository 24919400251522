import two from "../../../image/originals/abstracts/2.webp";
import two_back from "../../../image/originals/abstracts/2_1.webp";import ItemWide from "../ItemWide";

const Shop = () => {
  const items = [
    {
      id: "42_base",
      name: "Intentions",
      image: two, // Main image
      price: "$4000.00",
      thumbnails: [two, two_back], // Other images (if available)
      description: "Acrylic on canvas  Size: 34” x 72”  Personalized Quote Painted on the back of the piece.  (Unstretched)",
      projectUrl: "/Intentions",
      sizes: ["40 X 40"],
    },
  ];

  return <ItemWide items={items} />;
};

export default Shop;

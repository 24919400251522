import basq from "../../../image/originals/hollywoodicons/basq.webp";
import basq2 from "../../../image/originals/hollywoodicons/basq2.jpg"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "72_base",
      name: "Basquiat Details",
      image: basq, // Main image
      price: "empty for now",
      thumbnails: [basq, basq2], // Other images (if available)
      description: "30” x 35” in Acrylic on linen",
      projectUrl: "/BasquiatDetails",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import diana from "../../../image/originals/hollywoodicons/diana.webp"; import diana2 from "../../../image/originals/hollywoodicons/diana2.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "111_base",
      name: "Princess Diana Original",
      image: diana, // Main image
      price: "empty for now",
      thumbnails: [diana, diana2], // Other images (if available)
      description:
        "36 by 36 “ Acrylic and Glitter on canvas (+ back of painting photo)",
      projectUrl: "/PrincessDianaOriginal",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import steph_curry from "../../../image/originals/nba/steph_curry.webp"; import steph_curry_back from "../../../image/originals/nba/steph_curry_back.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: 0,
      name: "Stephen Curry",
      image: steph_curry, // Main image without .webp
      price: "empty for now",
      thumbnails: [steph_curry, steph_curry_back], // Other images (if available)
      description: "30” x 48” Acrylic, Glitter on Linen",
      projectUrl: "/StephenCurry",
      sizes: ["blank"],
      available: "sold",
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

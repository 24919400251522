import universe from "../../../image/originals/quantum/universe.webp";
import universe2 from "../../../image/originals/quantum/universe2.webp";

import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "18_base",
      name: "The Universe Original",
      image: [universe],
      price: "$8008.00",
      thumbnails: [universe, universe2], // Front and Back images (if available)
      description: "A breathtaking depiction of the universe.",
      projectUrl: "/UniverseOriginal",
      sizes: ["48 X 48"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

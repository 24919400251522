import artronaut from '../../image/stickers/artronaut1.webp'
import iam from "../../image/stickers/iam1.webp";
import just from "../../image/stickers/justa.webp";

import Collection from "./Collection"

const StickersCollection = () => {

  const title = "STICKERS"

  const items = [
    {
      title: "aRT Sticker Bundle",
      imageUrl: artronaut,
      projectUrl: "/StickerBundle",
    },
    {
      title: '"aRTronaut" Sticker',
      imageUrl: artronaut,
      projectUrl: "/Artronaut",
    },
    {
      title: "“Without RT” sticker",
      imageUrl: just,
      projectUrl: "/Without",
    },
    {
      title: "“I AM ART” sticker",
      imageUrl: iam,
      projectUrl: "/Iam",
    },
  ];

  return (
    <Collection items={items} title={title} />
  );
};

export default StickersCollection;

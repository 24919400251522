import romeo from "../../../image/originals/lockdown/04washinghands.webp";
import romeo1 from "../../../image/originals/lockdown/04washinghands_back.webp";

import ItemWide from "../ItemWide";

const Shop = () => {
  const items = [
    {
      id: "74_base",
      name: "WASHING HANDS (SELF- PORTRAIT), 2020",
      image: romeo,
      price: "$36000.00",
      thumbnails: [romeo, romeo1],
      description: "ACRYLIC, OIL PASTEL, ON CANVAS 43 IN X 109 IN",
      projectUrl: "",
      sizes: ["36 X 47"],
    },
  ];

  return <ItemWide items={items} />;
};

export default Shop;

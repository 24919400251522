import marleydreads from "../../../image/originals/hollywoodicons/marleydreads.webp"; import marleydreads2 from "../../../image/originals/hollywoodicons/marleydreads2.webp"; import marleydreads3 from "../../../image/originals/hollywoodicons/marleydreads3.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "70_base",
      name: "Bob Marley Dreads",
      image: marleydreads, // Main image
      price: "empty for now",
      thumbnails: [marleydreads, marleydreads2, marleydreads3], // Other images (if available)
      description: "36 x 36 in Acrylic, Oil Pastel and Marijuana on linen",
      projectUrl: "/BobMarleyDreads",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import image004 from "../../../image/originals/gold/004.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "36_base",
      name: "Pregnant with Newborn",
      image: image004,
      price: "$3000.00",
      thumbnails: [image004],
      description:
        "Acrylic and oil on canvas of a mother-to-be with her newborn.",
      projectUrl: "/PregnantWithNewborn",
      sizes: ["48 X 36"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import floating from "../../../image/originals/quantum/floating.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "100_base",
      name: "Floating in Space Print",
      image: floating, // Main image without .webp
      price: "empty for now",
      thumbnails: [floating], // Other images (if available)
      description: "20 x 20” Museum Rag Paper.",
      projectUrl: "/FloatinginSpacePrint",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import cosmowarrior from "../../../image/originals/quantum/cosmowarrior.webp";
import cosmowarrior_back from "../../../image/originals/quantum/cosmowarrior_back.webp";import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "12_base",
      name: "Cosmo Warrior",
      image: [cosmowarrior],
      price: "$14000.00",
      thumbnails: [cosmowarrior, cosmowarrior_back], // Front and Back images (if available)
      description: "Acrylic and oil on canvas portraying a cosmic warrior.",
      projectUrl: "/CosmoWarrior",
      sizes: ["50 X 40"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import vibe1 from "../../../image/originals/gold/vibe1.webp";
import vibe2 from "../../../image/originals/gold/vibe2.webp";
import vibe3 from "../../../image/originals/gold/vibe3.webp";

import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "31_base",
      name: "You're A Vibe",
      image: vibe1,
      price: "$6000.00",
      thumbnails: [vibe1, vibe2, vibe3],
      description: '36" x 42" Acrylic and oil pastel on canvas',
      projectUrl: "",
      sizes: ["36 X 47"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import jaydee from "../../../image/originals/hollywoodicons/jaydee.webp"; import jaydee2 from "../../../image/originals/hollywoodicons/jaydee2.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "112_base",
      name: "J Dilla",
      image: jaydee, // Main image
      price: "750",
      thumbnails: [jaydee, jaydee2], // Other images (if available)
      description: '24" by 36”Acrylic on Canvas Glows In The Dark(+ back of painting photo)',
      projectUrl: "/JDilla",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

import risks from "../../../image/originals/quantum/RISKS.webp";
import risks2 from "../../../image/originals/quantum/RISKS_BACK.webp";
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "9_base",
      name: "Risks Original",
      image: [risks],
      price: "$15000.00",
      thumbnails: [risks, risks2], // Front and Back images (if available)
      description: "Acrylic and oil on canvas depicting risks and rewards.",
      projectUrl: "/RisksOriginal",
      sizes: ["48 X 36"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;

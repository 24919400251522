import Prince from "../../../image/originals/hollywoodicons/Prince.webp"; 
import ItemNoSize from "../ItemNoSize";

const Shop = () => {
  const items = [
    {
      id: "52_base",
      name: "Prince",
      image: Prince, // Main image
      price: "$575.00",
      thumbnails: [Prince], // Other images (if available)
      description: '12" x 16" Quote behind the painting: "I like constructive criticism from smart people"',
      projectUrl: "/Prince",
      sizes: ["blank"],
    },
  ];

  return <ItemNoSize items={items} />;
};

export default Shop;
